import {Container} from '@/components/ui/container/Container'
import styled, {css, DefaultTheme} from 'styled-components'

export const StyledSidebarDetails = styled(Container)`
    ${({theme, open}: {theme: DefaultTheme; open: boolean}) => css`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: ${theme.zIndex.modal};
        width: 100%;
        max-width: 750px;
        background: ${theme.palette.neutral.white};
        box-shadow: ${theme.shadows.lg};
        transform: translateX(${open ? '0' : '100%'});
        transition: transform 0.3s ease-in-out;
        overflow-y: scroll;
        padding: ${theme.spacing * 4}px ${theme.spacing * 8}px;
    `}
`

export const StyledOverlaySidebar = styled.div`
    ${({theme, open}: {theme: DefaultTheme; open: boolean}) => css`
        position: fixed;
        inset: 0;
        background: rgba(52, 64, 84, 0.7);
        opacity: ${open ? 1 : 0};
        z-index: ${theme.zIndex.modalOverlay};
        backdrop-filter: blur(8px);
        transition: opacity 0.3s ease-in-out;
        transform: translateX(${open ? '0' : '100%'});
    `}
`

export const StyledSidebarTextContainer = styled.div`
    ${({theme: {typography, palette, spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        margin-bottom: ${spacing * 4}px;

        & h4 {
            color: ${palette.neutral[700]};
            ${typography.displaySm};
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            ${mediaQueries.m} {
                ${typography.displayMd};
            }
        }

        & p {
            color: ${palette.neutral[700]};
            ${typography.textSm};
        }

        & svg {
            flex-shrink: 0;
        }

        & span {
            color: ${palette.neutral[500]};
            ${typography.displaySm};
            ${mediaQueries.m} {
                ${typography.displayMd};
            }
        }

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 2}px;

            .title {
                margin-top: ${spacing * 8}px;
            }
        }
    `}
`
