import {FC, HTMLAttributes} from 'react'
import {StyledTableDataCell} from './style'

interface TableDataCellProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    isBold?: boolean
    size?: 'sm'
    isWarn?: boolean
}

export const TableDataCell: FC<TableDataCellProps> = ({
    className,
    children,
    isBold = false,
    isWarn = false,
    size = 'sm',
    ...rest
}) => {
    return (
        <StyledTableDataCell className={className} isBold={isBold} isWarn={isWarn} size={size} {...rest}>
            {children}
        </StyledTableDataCell>
    )
}
