import styled, {DefaultTheme, css} from 'styled-components'

type StyledChipProps = {
    status: 'success' | 'danger' | 'warning'
    isMobile: boolean | undefined
}

const ColorStyles = ({palette}: DefaultTheme, isMobile: boolean | undefined) => ({
    warning: css`
        ${isMobile ? `background-color: ${palette.warning[600]};` : `background-color: ${palette.warning[50]}`};
        color: ${palette.warning[600]};
    `,
    success: css`
        ${isMobile ? `background-color: ${palette.success[600]};` : `background-color: ${palette.success[50]}`};
        color: ${palette.success[600]};
    `,
    danger: css`
        ${isMobile ? `background-color: ${palette.danger[600]};` : `background-color: ${palette.danger[50]}`};
        color: ${palette.danger[600]};
    `
})

export const StyledChipStatusWrapper = styled.div<StyledChipProps>`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 10px;
        height: 10px;
        border-radius: 100%;

        .status-text {
            display: none;
            ${mediaQueries.m} {
                display: flex;
            }
        }
        ${mediaQueries.m} {
            width: 100%;
            height: 100%;
            display: flex;
            padding: ${spacing / 2}px ${spacing * 2}px;
            justify-content: center;
            align-items: center;
            border-radius: ${spacing * 2}px;
        }
    `}

    ${props => ColorStyles(props.theme, props.isMobile)[props.status]}
`
