import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '../keys'
import {PROJECTS_API} from '@/features/projects/services/projects.http.ts'

export const useGetProjects = (orderBy: string) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECTS, orderBy],
        queryFn: () => PROJECTS_API.getProjects(orderBy)
    })
}
