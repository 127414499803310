import {ColumnPendingTable, ColumnPendingTableProps} from '@/features/signup-requests/types.ts'
import {StyledButtonToAccept} from '@/features/signup-requests/components/requests-status/style.ts'
import {Checkbox} from '@components/commons/checkbox/CheckBox.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import dayjs from '@/dayjs.ts'
import {SIGNUP_UP_STATUS} from '@utilities/constants'

export const columnsRejectedTable = ({
    t,
    onSelect,
    selectedIds,
    handleActionRequest
}: ColumnPendingTableProps): ColumnPendingTable[] => {
    return [
        {
            label: t('requests:table:header:email'),
            width: '70%',
            cellRender: data => (
                <Flexbox align={'center'} gap={4}>
                    <Checkbox
                        id={data?.id.toString()}
                        onChange={() => onSelect(data?.id)}
                        checked={selectedIds.includes(data?.id)}
                    />
                    <p className={'email'}>{data?.email}</p>
                </Flexbox>
            )
        },
        {
            label: t('requests:table:header:rejected_date'),
            width: '15%',
            sortName: 'date',
            cellRender: data => <p>{dayjs(data?.deletedAt).format('DD/MM/YYYY')}</p>
        },
        {
            label: '',
            width: '15%',
            alignment: 'right',
            cellRender: data => (
                <StyledButtonToAccept
                    variant="green"
                    onClick={() =>
                        handleActionRequest({
                            ...data,
                            status: SIGNUP_UP_STATUS.pending
                        })
                    }
                >
                    {t('requests:table:body:send_again')}
                </StyledButtonToAccept>
            )
        }
    ] as ColumnPendingTable[]
}
