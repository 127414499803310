import {Table} from '@components/commons/table/Table.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TableDataCell} from '@components/commons/table/table-data-cell/TableDataCell.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ChipStatus} from '../chip-status/ChipStatus'
import {Job, ProjectDetail} from '../../types'
import {ProjectStatusSchema} from '../../schema'
import {StyledDetailsTableContainer, StyledMobileTextContainer, StyledScore} from './style'
import {truncateText, truncateTextBeforeExt} from '@utilities/helpers.ts'

type ProjectDetailsTableProps = {
    openDetails: (data: Job) => void
    filteredData: ProjectDetail | undefined
    isError: boolean
    isLoading: boolean
}

export const ProjectDetailsTable: FC<ProjectDetailsTableProps> = ({openDetails, filteredData, isLoading, isError}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])

    return (
        <>
            <StyledDetailsTableContainer>
                <Table
                    data={filteredData?.jobs || []}
                    isLoading={isLoading}
                    isError={isError}
                    isMobile={isMobile}
                    columnsMobile={[
                        {
                            width: '50%',
                            cellRender: data => (
                                <TableDataCell style={{textAlign: 'justify'}}>
                                    <StyledMobileTextContainer>
                                        <Flexbox direction={'column'} gap={1}>
                                            <Flexbox align={'center'} gap={1}>
                                                <span>{t('upload_pdf:table:file_name')}:</span>
                                                <p>{data?.fileName ? truncateTextBeforeExt(data.fileName) : '-'}</p>
                                            </Flexbox>
                                            <Flexbox align={'center'} gap={1}>
                                                <span>{t('upload_pdf:table:job_title')}:</span>
                                                <p>
                                                    {data.status === ProjectStatusSchema.Enum.completed && data.name
                                                        ? truncateText(data.name, isMobile ? 17 : 50)
                                                        : '-'}
                                                </p>
                                            </Flexbox>
                                            <Flexbox align={'center'} gap={1}>
                                                <span>{t('upload_pdf:table:total_score')}:</span>
                                                <StyledScore score={data?.score ?? 0}>
                                                    {data.score === 0 ? '0%' : data.score ? `${data.score}%` : ' -'}
                                                </StyledScore>
                                            </Flexbox>
                                        </Flexbox>
                                    </StyledMobileTextContainer>
                                </TableDataCell>
                            )
                        },
                        {
                            width: '50%',
                            cellRender: data => (
                                <TableDataCell>
                                    <Flexbox align="center" direction={'column'} gap={2}>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => openDetails(data)}
                                            disabled={
                                                data.status === ProjectStatusSchema.Enum.fail ||
                                                data.status === ProjectStatusSchema.Enum.pending
                                            }
                                        >
                                            <ChipStatus status={data.status} isMobile={isMobile} />
                                            <p>{t('commons:display')}</p>
                                        </Button>

                                        <span className={'average-time'}>
                                            {t('upload_pdf:table:estimated_time')}: {data?.avgCompletedInMin ?? '-'}{' '}
                                            {data?.avgCompletedInMin && t('upload_pdf:table:time')}
                                        </span>
                                    </Flexbox>
                                </TableDataCell>
                            )
                        }
                    ]}
                    columns={[
                        {
                            label: t('upload_pdf:table:file_name'),
                            width: '25%',
                            cellRender: data => (
                                <TableDataCell isBold>
                                    <p>{data.fileName}</p>
                                </TableDataCell>
                            )
                        },

                        {
                            label: t('upload_pdf:table:job_title'),
                            width: '25%',
                            cellRender: data => (
                                <TableDataCell>
                                    <p>
                                        {data.status === ProjectStatusSchema.Enum.completed && data.name
                                            ? data.name
                                            : '-'}
                                    </p>
                                </TableDataCell>
                            )
                        },

                        {
                            label: t('upload_pdf:table:status'),
                            width: '15%',
                            cellRender: data => {
                                return (
                                    <TableDataCell>
                                        <ChipStatus status={data.status} />
                                    </TableDataCell>
                                )
                            }
                        },

                        {
                            label: t('upload_pdf:table:total_score'),
                            width: '15%',
                            alignment: 'center',
                            cellRender: data => {
                                return (
                                    <TableDataCell>
                                        <Flexbox gap={2}>
                                            {data.score === null ? (
                                                <p>{'-'}</p>
                                            ) : data.score === 0 ? (
                                                <p>{'0%'}</p>
                                            ) : (
                                                <StyledScore score={data?.score ?? 0}>{`${data.score}%`}</StyledScore>
                                            )}
                                        </Flexbox>
                                    </TableDataCell>
                                )
                            }
                        },
                        {
                            label: t('upload_pdf:table:estimated_time'),
                            width: '10%',
                            alignment: 'center',
                            cellRender: data => {
                                return (
                                    <TableDataCell>
                                        <p>
                                            {data?.avgCompletedInMin ?? '-'}{' '}
                                            {data?.avgCompletedInMin && t('upload_pdf:table:time')}
                                        </p>
                                    </TableDataCell>
                                )
                            }
                        },

                        {
                            label: ' ',
                            width: '10%',
                            alignment: 'right',
                            cellRender: data => (
                                <Flexbox align="center" gap={4}>
                                    <Button
                                        variant="green"
                                        size="sm"
                                        onClick={() => openDetails(data)}
                                        disabled={data.status !== ProjectStatusSchema.Enum.completed}
                                    >
                                        <p>{t('commons:display')}</p>
                                    </Button>
                                </Flexbox>
                            )
                        }
                    ]}
                />
            </StyledDetailsTableContainer>
        </>
    )
}
