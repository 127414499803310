import {InputText} from '@/components/commons/input-text/InputText'
import {SidebarDetails} from '@/components/commons/sidebar-details/SidebarDetails'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {BackIcon, CalendarIcon, ClockIcon, SearchLgIcon} from '@/components/ui/icon'
import {ProjectDetailsTable} from '@/features/projects/components/project-details/ProjectDetailsTable'
import {CardDetails} from '@/features/projects/components/sidebar-file-details/components/card-details/CardDetails'
import {TotalScoreBanner} from '@/features/projects/components/sidebar-file-details/components/total-score-banner/TotalScoreBanner'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {StyledDate, StyledHeaderContainer} from './style'
import {formatDate} from '@/dayjs'
import {useProjectDetail} from '@/features/projects/queries/useProjectDetail'
import {Job} from '@/features/projects/types'
import {useInputSearch} from '@/hooks/useInputSearch'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import {routes} from '@utilities/constants'

export const ProjectDetail = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [sidebarInfo, setSidebarInfo] = useState<Job | null>(null)
    const [openSidebar, setOpenSidebar] = useState(false)
    const {id} = useParams()
    const projectId = Number(id)
    const {data, isLoading, isError, error} = useProjectDetail(projectId)
    const [filteredData, setFilteredData] = useState(data)
    const {onSearch, searchValue} = useInputSearch()

    if (isError) {
        const errorObject = error as AxiosError
        handleGenericError({error: errorObject, t})
    }

    useEffect(() => {
        if (data && searchValue) {
            const jobs = data?.jobs as Job[]
            setFilteredData({
                ...data,
                jobs: jobs.filter(
                    job =>
                        job.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        job.fileName.toLowerCase().includes(searchValue.toLowerCase())
                )
            })
        } else {
            setFilteredData(data)
        }
    }, [searchValue, data])

    const openDetails = (data: Job) => {
        setOpenSidebar(true)
        setSidebarInfo(data)
    }

    return (
        <PageLayout>
            <StyledHeaderContainer>
                <div>
                    <Flexbox gap={2} align="center" onClick={() => navigate(routes.HOME.path)}>
                        <BackIcon size={18} viewBox="0 -960 960 960" cursor={'pointer'} />
                        <p className={'back-text'}>{t('upload_pdf:back_to_projects')} </p>
                    </Flexbox>

                    <h4>{data?.title}</h4>
                    <Flexbox gap={2}>
                        <Flexbox align="center" gap={1}>
                            <CalendarIcon size={12} viewBox="0 0 12 12" />
                            <StyledDate>{formatDate(data?.createdAt || '', 'DD/MM/YYYY')}</StyledDate>
                        </Flexbox>
                        <Flexbox align="center" gap={1}>
                            <ClockIcon size={12} />
                            <StyledDate>{formatDate(data?.createdAt || '', 'HH:mm')}</StyledDate>
                        </Flexbox>
                    </Flexbox>
                </div>
                <InputText
                    type={'text'}
                    placeholder={t('commons:search')}
                    typeIcon={<SearchLgIcon size={18} />}
                    className="input-search"
                    onChange={e => {
                        onSearch(e.currentTarget.value)
                    }}
                />
            </StyledHeaderContainer>

            <ProjectDetailsTable
                openDetails={openDetails}
                isLoading={isLoading}
                isError={isError}
                filteredData={filteredData}
            />

            <SidebarDetails
                onClose={() => setOpenSidebar(false)}
                open={openSidebar}
                title={data?.title || ''}
                subtitle={t('sidebar_details:subtitle')}
                sidebarInfo={sidebarInfo}
            >
                <TotalScoreBanner job={sidebarInfo} />
                {sidebarInfo && <CardDetails data={sidebarInfo} />}
            </SidebarDetails>
        </PageLayout>
    )
}

ProjectDetail.displayName = 'ProjectDetail'
