import {StyledColumn} from './style'
import {AccordionCard} from '../accordion-card/AccordionCard'
import {Job} from '@/features/projects/types'
import {FC} from 'react'

type CardDetailsProps = {
    data: Job
}

export const CardDetails: FC<CardDetailsProps> = ({data}) => {
    const columns = [
        data?.responses?.slice(0, Math.ceil(data?.responses?.length / 2)),
        data?.responses?.slice(Math.ceil(data?.responses?.length / 2))
    ]

    return (
        <StyledColumn>
            {data &&
                columns.map((columnData, columnIndex) => (
                    <div key={columnIndex} className={'card-container'}>
                        {columnData?.map((data, index) => (
                            <AccordionCard
                                key={index}
                                title={data.question.question}
                                status={data.isCorrect}
                                description={data.response}
                            />
                        ))}
                    </div>
                ))}
        </StyledColumn>
    )
}

CardDetails.displayName = 'CardDetails'
