import axios from 'axios'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'
import {envVars} from '@/envVars.ts'
import {useAuthStore} from '@/store/useAuthStore.tsx'

const axiosConfig = {
    baseURL: envVars.VITE_API_ENDPOINT,
    headers: {
        common: {
            Authorization: localStorage.getItem('accessToken')
                ? `Bearer ${localStorage.getItem('accessToken')}`
                : false,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
}

// create axios custom instance with custom config
const axiosInstance = axios.create(axiosConfig)

const attemptRefresh = async (refreshToken: string) => {
    const url = 'auth/refresh'
    try {
        const {data} = await axiosInstance.post(url, {token: refreshToken})
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, data.accessToken)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken)
        useAuthStore.setState(() => ({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken
        }))

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            localStorage.clear()
            window.location.href = '/login'
        } else {
            console.error(error)
        }
    }
}

axiosInstance.interceptors.request.use(request => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    if (token) request.headers['Authorization'] = `Bearer ${token}`
    return request
})

const endpointToIgnore = ['/login']

axiosInstance.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    async error => {
        // get error info
        const statusCode = error?.response?.status
        const originalRequest = error.config
        console.log('error', error)
        switch (statusCode) {
            case 401: {
                const refreshToken = useAuthStore.getState().refreshToken
                localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
                if (refreshToken && !originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
                    originalRequest._retry = true // prevent infinite retry loop
                    await attemptRefresh(refreshToken)
                    return axiosInstance.request(originalRequest)
                } else {
                    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
                    useAuthStore.setState(() => ({}))
                    return Promise.reject(error)
                }
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default axiosInstance
