import styled, {DefaultTheme, css} from 'styled-components'

type StyledDataCellProps = {
    theme: DefaultTheme
    isBold?: boolean
    isWarn?: boolean
    size: 'sm' | 'md'
}

export const StyledTableDataCell = styled.div<StyledDataCellProps>`
    ${({theme: {palette}, isBold, isWarn}) => css`
        font-weight: ${isBold ? '700' : '400'};
        color: ${isWarn ? palette.danger[500] : isBold ? palette.neutral[900] : palette.neutral[600]};
        p,
        a {
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        a {
            color: inherit;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `}

    ${({size, theme}) => SizeStyles({theme})[size]};
`

type SizesProps = {
    theme: DefaultTheme
}

const SizeStyles = ({theme: {typography}}: SizesProps) => ({
    sm: css`
        ${typography.textSm}
    `,
    md: css`
        ${typography.textMd}
    `
})
