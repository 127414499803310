import {RefObject, useState} from 'react'
import {debounce} from '@/utilities/helpers'

export const useInputSearch = (defaultSearchValue?: string) => {
    const [searchValue, setSearchValue] = useState(defaultSearchValue ?? '')

    const searchCallback = (value: string) => {
        if (value?.length >= 1) {
            setSearchValue(value)
        } else if (!value?.length) {
            setSearchValue('')
        }
    }

    const onSearch = debounce((value: string) => {
        searchCallback(value)
    }, 100)

    const onResetSearch = (searchRef: RefObject<HTMLInputElement | null>) => {
        if (searchRef.current) {
            searchRef.current.value = ''
        }
        setSearchValue('')
    }

    return {
        searchValue,
        onSearch,
        onResetSearch
    }
}
