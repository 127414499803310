import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledHeaderWrapper = styled(Flexbox)`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        justify-content: space-between;
        text-align: start;

        ${mediaQueries.m} {
            text-align: start;
        }

        & > h4 {
            ${typography.textXl};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textMd};
        }

        .button-login {
            margin-top: ${spacing * 2}px;
        }
    `}
`
