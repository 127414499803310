import {FC, ReactNode} from 'react'
import {StyledModal, StyledOverlay} from '@components/ui/modal/style.ts'
import {createPortal} from 'react-dom'

interface ModalProps {
    children: ReactNode
    className?: string
    closeIconOffset?: 'sm' | 'md' | 'lg'
    onClose?: () => void
    showCloseButton?: boolean
    width?: number
}

export const Modal: FC<ModalProps> = ({children, className, onClose, width}) => {
    return createPortal(
        <>
            <StyledOverlay onClick={onClose} />
            <StyledModal width={width} className={className}>
                {children}
            </StyledModal>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'
