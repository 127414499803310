import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import {Header} from '@components/commons/header/Header.tsx'

export const PrivateLayout = () => {
    return (
        <StyledPrivateLayout>
            <Header />
            <StyledMain>
                <Outlet />
            </StyledMain>
        </StyledPrivateLayout>
    )
}
