import {useQuery} from '@tanstack/react-query'
import {SIGN_UP_REQUESTS_API} from '@/features/signup-requests/services/signupRequests.http.ts'
import {QUERY_KEYS} from '@/features/signup-requests/keys.ts'
import {AdminConfigItem} from '../types'

export const useGetSignUpRequests = (status: string, orderBy: string, adminConfig: AdminConfigItem) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SIGNUP_REQUESTS, status, orderBy, adminConfig],
        queryFn: () => SIGN_UP_REQUESTS_API.getSignUpRequests(status, orderBy)
    })
}
