import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledProgressLoaderContainer = styled(Flexbox)`
    ${({theme}) => css`
        z-index: 10;

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .value {
            color: ${theme.palette.neutral.white};
            font-weight: 700;
        }
    `}
`
