import {create} from 'zustand'
import {AuthState} from '@/features/auth/type.ts'
import {persist} from 'zustand/middleware'
import {createAuthDataSlice} from '@/store/slices/authStoreSlice.tsx'
import {AuthStoreActions} from '@/store/slices/types.ts'

export const useAuthStore = create<AuthState & AuthStoreActions>()(
    persist(
        (...a) => ({
            ...createAuthDataSlice(...a)
        }),
        {name: 'auth-store'}
    )
)
