import styled, {css} from 'styled-components'
import {InputText} from '@components/commons/input-text/InputText.tsx'

export const StyledTitle = styled.h1`
    ${({theme}) => css`
        font-size: 28px;
        color: ${theme.palette.neutral['700']};
        font-weight: 700;
        margin-bottom: ${theme.spacing * 6}px;

        ${theme.mediaQueries.m} {
            ${theme.typography.displayLg};
            margin-bottom: ${theme.spacing * 8}px;
        }
    `}
`

export const StyledOptionsToSelect = styled.div`
    ${({theme}) => css`
        color: ${theme.palette.neutral['700']};
        ${theme.typography.textSm};
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 3}px;
    `}
`

export const StyledInputText = styled(InputText)`
    ${({theme}) => css`
        margin-bottom: ${theme.spacing * 4}px;

        & .search-input {
            border-radius: ${theme.spacing * 4}px;
        }
    `}
`
