import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledWrapperSwitch = styled.div`
    ${({theme}) => css`
        padding: ${theme.spacing * 6}px 0;
    `}
`

export const StyledCancelApprove = styled(Button)`
    ${({theme}) => css`
        background-color: ${theme.palette.neutral[500]};
        color: ${theme.palette.neutral.white};
        ${theme.typography.textLg};
        font-weight: 700;
    `}
`

export const StyledConfirmApprove = styled(Button)`
    ${({theme}) => css`
        background-color: transparent;
        color: ${theme.palette.warning[500]};
        ${theme.typography.textLg};
        font-weight: 700;
    `}
`
