import {AlertRedCircleIcon} from '@/components/ui/icon'
import {Modal} from '@/components/ui/modal/Modal'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledButtonContainer,
    StyledCancelButton,
    StyledDeleteButton,
    StyledDeleteModalWrapper,
    StyledHeaderWrapper
} from './style'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useDeleteProject} from '@/features/projects/queries/useDeleteProject'

type DeleteFileModalProps = {
    onClose: () => void
    projectToDelete: number
    setProjectToDelete: Dispatch<SetStateAction<number | undefined>>
}

export const DeleteFileModal: FC<DeleteFileModalProps> = ({onClose, projectToDelete, setProjectToDelete}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 415, [width])

    const {mutate: deleteMutation} = useDeleteProject()

    return (
        <Modal width={544} onClose={onClose} closeIconOffset="sm">
            <StyledDeleteModalWrapper>
                <AlertRedCircleIcon size={48} viewBox="0 0 48 48" />

                <div>
                    <StyledHeaderWrapper direction={'column'} gap={2}>
                        <h4>{t('upload_pdf:delete_modal:title')}</h4>
                        <p>{t('upload_pdf:delete_modal:subtitle')}</p>
                    </StyledHeaderWrapper>

                    <StyledButtonContainer>
                        <StyledCancelButton variant="ghost" onClick={() => onClose()} fullWidth={isMobile}>
                            {t('commons:cancel')}
                        </StyledCancelButton>
                        <StyledDeleteButton
                            variant={'ghost'}
                            onClick={() => {
                                deleteMutation(projectToDelete)
                                setProjectToDelete(undefined)
                            }}
                            fullWidth={isMobile}
                        >
                            {t('commons:delete')}
                        </StyledDeleteButton>
                    </StyledButtonContainer>
                </div>
            </StyledDeleteModalWrapper>
        </Modal>
    )
}

DeleteFileModal.displayName = 'DeleteFileModal'
