import React, {useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {SignUpFormSchema, SignUpValidationSchema} from './signUpFormSchema'
import {StyledSignUpFormWrapper, StyledSignUpHeaderWrapper} from './style'
import {routes} from '@/utilities/constants'
import {StyledLink, StyledNoAccountParagraph} from '@pages/login/style.ts'
import {ClockGreenCircleIcon, EmailIcon} from '@/components/ui/icon'
import {StyledHeaderWrapper} from '@/features/auth/components/password-recovery-modal/style.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'
import {handleGenericError} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

export const SignUp: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [signUpSent, setSignUpSent] = useState<boolean>(false)
    const [isAutoApproveActive, setIsAutoApproveActive] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isSubmitting, isValid}
    } = useForm<SignUpValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(SignUpFormSchema)
    })

    const onSubmit: SubmitHandler<SignUpValidationSchema> = async values => {
        setIsLoading(true)
        try {
            const {data} = await AUTH_API.signup(values)
            setIsAutoApproveActive(data?.adminConfig?.isActive)
            setSignUpSent(true)
        } catch (e) {
            const error = e as AxiosError
            handleGenericError({error, t})
        } finally {
            setIsLoading(false)
            reset()
        }
    }

    return (
        <>
            {isLoading && <Spinner />}
            <StyledSignUpFormWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledSignUpHeaderWrapper>
                    <h4>{t('sign_up:title')}</h4>
                    <p>{t('sign_up:subtitle')}</p>
                </StyledSignUpHeaderWrapper>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <InputText
                        type={'email'}
                        label={t('form:email_label')}
                        placeholder={t('form:email_placeholder')}
                        errorMessage={t(errors.email?.message || '')}
                        {...register('email')}
                        inputSize={'sm'}
                        typeIcon={<EmailIcon fill={'white'} size={20} viewBox="0 0 20 20" />}
                    />

                    <InputText
                        type={'password'}
                        label={t('form:password_label')}
                        placeholder={t('form:password_placeholder')}
                        errorMessage={t(errors.password?.message || '')}
                        {...register('password')}
                        visibilityToggle
                        inputSize={'sm'}
                    />

                    <InputText
                        type={'password'}
                        label={t('form:confirm_password_label')}
                        placeholder={t('form:confirm_password_placeholder')}
                        errorMessage={t(errors.passwordConfirmation?.message || '')}
                        {...register('passwordConfirmation')}
                        visibilityToggle
                        inputSize={'sm'}
                    />
                </Flexbox>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <Button variant={'green'} type={'submit'} size={'sm'} disabled={isSubmitting || !isValid} fullWidth>
                        {t('sign_up:cta')}
                    </Button>

                    <Flexbox gap={4} width={'360px'} justify={'center'} align={'flex-end'}>
                        <StyledNoAccountParagraph>{t('sign_up:already_account')}</StyledNoAccountParagraph>
                        <StyledLink to={routes.LOGIN.path}>{t('sign_up:login')}</StyledLink>
                    </Flexbox>
                </Flexbox>
            </StyledSignUpFormWrapper>

            {signUpSent && (
                <Modal width={450} onClose={() => setSignUpSent(false)}>
                    <Flexbox gap={6} padding={6}>
                        <ClockGreenCircleIcon size={48} viewBox="0 0 48 48" fill={'#D1FADF'} />
                        <StyledHeaderWrapper direction={'column'}>
                            <h4>
                                {t(
                                    isAutoApproveActive
                                        ? 'sign_up:success:title_with_auto_approve'
                                        : 'sign_up:success:title'
                                )}
                            </h4>
                            <p>
                                {t(
                                    isAutoApproveActive
                                        ? 'sign_up:success:subtitle_with_auto_approve'
                                        : 'sign_up:success:subtitle'
                                )}
                            </p>

                            {isAutoApproveActive && (
                                <Button
                                    className={'button-login'}
                                    variant={'green'}
                                    size={'sm'}
                                    onClick={() => navigate(routes.LOGIN.path)}
                                >
                                    {t('login:cta')}
                                </Button>
                            )}
                        </StyledHeaderWrapper>
                    </Flexbox>
                </Modal>
            )}
        </>
    )
}

SignUp.displayName = 'SignUp'
