import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

export const StyledLoginHeaderWrapper = styled(Flexbox)`
    ${({theme: {typography, spacing, palette, mediaQueries}}) => css`
        color: ${palette.neutral['700']};
        padding-bottom: ${spacing * 4}px;

        & > h4 {
            ${typography.displayLg};
            font-weight: 700;
            text-align: center;
        }

        & > p {
            width: 330px;
            ${typography.textLg};
            text-align: center;

            ${mediaQueries.s} {
                width: 360px;
            }
        }
    `}
`

export const StyledButtonLink = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary[700]};
        cursor: pointer;
        ${typography.textMd}
    `}
`

export const StyledLink = styled(Link)`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.neutral['black']};
        cursor: pointer;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-underline-offset: 3px;
        font-weight: 700;
        ${typography.textMd}
    `}
`

export const StyledNoAccountParagraph = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
    `}
`
