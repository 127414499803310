import {StyledTCell, StyledTRowWrapper, StyledTRow} from '@/components/commons/table/desktop-t-body/style'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {TableColumn} from '@components/commons/table/Table.tsx'

interface DesktopSkeletonProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
}

export const DesktopSkeleton = <TData extends unknown[], TSortName extends string | undefined>({
    columns
}: DesktopSkeletonProps<TData, TSortName>) => {
    return (
        <>
            {Array.from(Array(3)).map((_, index) => (
                <StyledTRowWrapper key={index}>
                    <StyledTRow>
                        {columns.map((column, index) => (
                            <StyledTCell alignment={column.alignment} width={column.width} key={index}>
                                <Skeleton height={24} key={index} />
                            </StyledTCell>
                        ))}
                    </StyledTRow>
                </StyledTRowWrapper>
            ))}
        </>
    )
}

DesktopSkeleton.displayName = 'TableDesktopSkeleton'
