import {Outlet} from 'react-router-dom'
import React from 'react'
import {Header} from '@components/commons/header/Header.tsx'
import {useTranslation} from 'react-i18next'
import {routes} from '@utilities/constants'

export const PublicLayout: React.FC = () => {
    const {t} = useTranslation()

    const headerItems = [
        {name: t('login:title'), path: routes.LOGIN.path},
        {name: t('login:signup'), path: routes.SIGNUP.path}
    ]
    return (
        <>
            <Header items={headerItems} />
            <Outlet />
        </>
    )
}
