import styled, {css} from 'styled-components'
import * as RadixSwitch from '@radix-ui/react-switch'

export const StyledSwitchWrapper = styled.form`
    ${({theme}) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 4}px;
    `}
`

export const StyledSwitchLabel = styled.label`
    ${({theme}) => css`
        cursor: pointer;
        ${theme.typography.textMd};
        color: ${theme.palette.neutral['700']};
    `}
`

export const StyledSwitchInput = styled(RadixSwitch.Root)`
    ${({theme}) => css`
        width: 56px;
        height: 24px;
        background-color: ${theme.palette.neutral['400']};
        border-radius: ${theme.spacing * 3.5}px;
        position: relative;
        transition: background-color 0.2s ease;
        cursor: pointer;
        &[data-state='checked'] {
            background-color: ${theme.palette.green['400']};
        }
    `}
`

export const StyledSwitchThumb = styled(RadixSwitch.Thumb)`
    ${({theme}) => css`
        width: 18px;
        height: 18px;
        background-color: ${theme.palette.neutral['white']};
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 4px;
        transition: transform 0.2s ease;
        &[data-state='checked'] {
            transform: translateX(31px);
        }
    `}
`
