import {FC} from 'react'
import {StyledHeaderWrapper, StyledStatusWrapper} from './style'
import {GreenCircleIcon, XCircleIcon} from '@/components/ui/icon'
import {useTranslation} from 'react-i18next'

type InfoCardProps = {
    title: string
    status: boolean
}

export const InfoCard: FC<InfoCardProps> = ({title, status}) => {
    const {t} = useTranslation()

    return (
        <>
            <StyledHeaderWrapper>
                <h4>{title}</h4>
            </StyledHeaderWrapper>

            <StyledStatusWrapper>
                {!status ? (
                    <>
                        <XCircleIcon size={24} viewBox="0 0 24 24" />
                        <p>{t('sidebar_details:status_no')}</p>
                    </>
                ) : (
                    <>
                        <GreenCircleIcon size={20} viewBox="0 0 20 20" />
                        <p>{t('sidebar_details:status_yes')}</p>
                    </>
                )}
            </StyledStatusWrapper>
        </>
    )
}
