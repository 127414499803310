import {StyledOptionsToSelect} from '@pages/signup-requests/styles.ts'
import {Switch} from '@components/commons/switch/Switch.tsx'
import {AlertWarningCircleIcon, InfoIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledCancelApprove,
    StyledConfirmApprove,
    StyledWrapperSwitch
} from '@/features/signup-requests/components/requests-header/style.ts'
import {FC, useEffect, useMemo, useState} from 'react'
import {RequestType, ServiceDetailsSearchParams} from '@/features/signup-requests/types.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {StyledHeaderWrapper} from '@/features/auth/components/password-recovery-modal/style.ts'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {SIGNUP_UP_STATUS} from '@utilities/constants'
import {useActionMultipleRequests} from '@/features/signup-requests/queries/useActionMultipleRequests.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {useGetAdminConfig} from '@/features/signup-requests/queries/useGetAdminConfig.ts'
import {useSwitchToAutoApprove} from '@/features/signup-requests/queries/useSwitchToAutoApprove.ts'
import toast from 'react-hot-toast'
import {handleGenericError} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'
import {StyledDeleteModalWrapper} from '@/features/projects/components/create-project/uploader-modals/style'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

type HeaderRequestsTableProps = {
    data?: RequestType[]
    selectedIds?: number[]
    setSelectedIds?: (ids: number[]) => void
}

export const HeaderRequestsTable: FC<HeaderRequestsTableProps> = ({data, selectedIds, setSelectedIds}) => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], ServiceDetailsSearchParams)

    const {data: adminConfig, isError, error} = useGetAdminConfig()
    const switchToAutoApproveMutation = useSwitchToAutoApprove()
    const actionMultipleRequests = useActionMultipleRequests()

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [switchToAutoApprove, setSwitchToAutoApprove] = useState<boolean>(false)

    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 415, [width])

    const userObj = adminConfig?.find(item => item.id)
    const isAutoApproveEnabled = userObj?.isActive as boolean

    if (isError) {
        const errorObject = error as AxiosError
        handleGenericError({error: errorObject, t})
    }

    const onSelectAll = () => {
        const allIds = data?.map(item => item.id) || []
        if (setSelectedIds) {
            setSelectedIds(allIds)
        }
    }

    const onDeselectAll = () => {
        if (setSelectedIds) {
            setSelectedIds([])
        }
    }

    const enableAutoApprove = async () => {
        await switchToAutoApproveMutation.mutateAsync({
            status: !isAutoApproveEnabled,
            userID: userObj?.id || 1
        })
        setOpenModal(false)
        toast.success(
            t(!isAutoApproveEnabled ? 'requests:automatic_approve_enabled' : 'requests:automatic_approve_disabled')
        )
    }

    const handleAction = async (status: string) => {
        const payload = {
            signupRequestsIds: selectedIds || [],
            status: status
        }
        await actionMultipleRequests.mutateAsync(payload)
        onDeselectAll()
        setOpenModal(false)
    }

    useEffect(() => {
        setSwitchToAutoApprove(isAutoApproveEnabled)
    }, [isAutoApproveEnabled])

    return (
        <>
            {actionMultipleRequests.isPending && <Spinner />}
            <Flexbox justify={'flex-start'} align={'flex-end'} direction={'column'} gap={6} width={'100%'}>
                <StyledWrapperSwitch>
                    <Switch
                        value={switchToAutoApprove}
                        defaultChecked={switchToAutoApprove}
                        label={t('requests:switch_label')}
                        name={'switch'}
                        icon={
                            <Tooltip
                                variant={'light'}
                                trigger={
                                    <Flexbox>
                                        <InfoIcon
                                            size={16}
                                            viewBox={'0 0 16 16'}
                                            fill={'#fff'}
                                            cursor={'pointer'}
                                            onClick={e => e.preventDefault()}
                                        />
                                    </Flexbox>
                                }
                                content={t('requests:table:header:tooltip')}
                            />
                        }
                        onChange={() => setOpenModal(!openModal)}
                    />
                </StyledWrapperSwitch>

                {selectedIds && selectedIds?.length > 0 && (
                    <Flexbox justify={'space-between'} align={'center'} gap={4} width={'100%'} padding={2}>
                        <StyledOptionsToSelect>
                            <p onClick={onSelectAll}>{t('requests:select_all')}</p>
                            <p onClick={onDeselectAll}>{t('requests:deselect_all')}</p>
                        </StyledOptionsToSelect>

                        {searchParams?.status === SIGNUP_UP_STATUS.rejected ? (
                            <StyledOptionsToSelect>
                                <p onClick={() => handleAction(SIGNUP_UP_STATUS.pending)}>
                                    {t('requests:send_request', {count: selectedIds?.length})}
                                </p>
                            </StyledOptionsToSelect>
                        ) : (
                            <StyledOptionsToSelect>
                                <p onClick={() => handleAction(SIGNUP_UP_STATUS.approved)}>
                                    {t('requests:accept_request')}
                                </p>
                                <p onClick={() => handleAction(SIGNUP_UP_STATUS.rejected)}>
                                    {t('requests:reject_request', {count: selectedIds?.length})}
                                </p>
                            </StyledOptionsToSelect>
                        )}
                    </Flexbox>
                )}
            </Flexbox>

            {openModal && (
                <StyledDeleteModalWrapper>
                    <Modal width={544} closeIconOffset="sm" onClose={() => setOpenModal(false)}>
                        <Flexbox direction={'column'} padding={6} align={'flex-end'} gap={8}>
                            <Flexbox gap={6} direction={isMobile ? 'column' : 'row'} align="center">
                                <AlertWarningCircleIcon size={56} viewBox="0 0 56 56" fill={'#FEF0C7'} />

                                <StyledHeaderWrapper direction={'column'} gap={2}>
                                    <h4>
                                        {t(
                                            isAutoApproveEnabled
                                                ? 'requests:automatic_decline_title'
                                                : 'requests:automatic_approve_title'
                                        )}
                                    </h4>
                                    <p>
                                        {t(
                                            isAutoApproveEnabled
                                                ? 'requests:automatic_decline_subtitle'
                                                : 'requests:automatic_approve_subtitle'
                                        )}
                                    </p>
                                </StyledHeaderWrapper>
                            </Flexbox>

                            <Flexbox gap={3} width={'100%'} justify={isMobile ? 'center' : 'flex-end'}>
                                <StyledCancelApprove variant={'green'} onClick={() => setOpenModal(false)}>
                                    {t('commons:cancel')}
                                </StyledCancelApprove>
                                <StyledConfirmApprove variant={'ghost'} onClick={enableAutoApprove}>
                                    {t('commons:confirm')}
                                </StyledConfirmApprove>
                            </Flexbox>
                        </Flexbox>
                    </Modal>
                </StyledDeleteModalWrapper>
            )}
        </>
    )
}
