import {HeaderWrapper, HeaderGrid, MenuItems} from '@components/commons/header/style.ts'
import {Container} from '@components/ui/container/Container.tsx'
import {NavLink} from '@components/ui/navlink/NavLink.tsx'
import Logo from '@assets/images/logo_icon.svg'
import {LogoutIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useAuthStore} from '@/store/useAuthStore.tsx'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'
import {useTranslation} from 'react-i18next'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN, routes} from '@utilities/constants'
import {useNavigate} from 'react-router-dom'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useMemo} from 'react'

export type MenuItem = {
    name: string
    path: string
}

type HeaderProps = {
    items?: MenuItem[]
}

export const Header = ({items}: HeaderProps) => {
    const {t} = useTranslation()
    const {user} = useAuthStore()
    const navigate = useNavigate()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])

    const logout = async () => {
        try {
            await AUTH_API.logout()
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
            useAuthStore.setState(() => ({user: null, accessToken: undefined, refreshToken: undefined}))
        } catch (e) {
            const error = e as AxiosError
            handleGenericError({error, t})
        }
    }

    return (
        <HeaderWrapper>
            <Container>
                <HeaderGrid user={user}>
                    <img src={Logo} alt={'logo'} height={40} width={160} onClick={() => navigate(routes.HOME.path)} />
                    {!user ? (
                        !isMobile && (
                            <MenuItems>
                                {items?.map(({name, path}) => (
                                    <NavLink path={path} name={name} key={name} size={'lg'} />
                                ))}
                            </MenuItems>
                        )
                    ) : (
                        <Flexbox gap={2} align={'center'} className={'logout-container'}>
                            <p className="user-email">{user?.email}</p>
                            <LogoutIcon
                                cursor={'pointer'}
                                viewBox={'0 0 20 20'}
                                fill={'#fff'}
                                size={20}
                                onClick={logout}
                            />
                        </Flexbox>
                    )}
                </HeaderGrid>
            </Container>
        </HeaderWrapper>
    )
}
