import styled, {css} from 'styled-components'

export const StyledSignUpHeaderWrapper = styled.div`
    ${({theme: {typography, spacing, palette, mediaQueries}}) => css`
        width: 330px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;
        padding-bottom: ${spacing * 8}px;

        ${mediaQueries.s} {
            width: 360px;
        }

        & > h4 {
            margin-top: ${spacing * 6}px;
            ${typography.displayLg};
            font-weight: 700;
            color: ${palette.neutral['900']};
            text-align: center;
        }

        & > p {
            width: 330px;
            ${typography.textLg};
            text-align: center;
            color: ${palette.neutral['700']};
            ${mediaQueries.s} {
                width: 360px;
            }
        }
    `}
`

export const StyledSignUpFormWrapper = styled.form`
    ${({theme}) => {
        const iosMobile = window.navigator.userAgent.includes('iPhone') && window.navigator.userAgent.includes('Mobile')
        const androidMobile =
            window.navigator.userAgent.includes('Android') && window.navigator.userAgent.includes('Mobile')

        const isSmartphone = iosMobile || androidMobile
        return css`
            max-width: 330px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ${theme.mediaQueries.s} {
                max-width: 360px;
            }

            .input-wrapper {
                width: 100%;
                padding-bottom: ${theme.spacing * 6}px;
            }

            ${isSmartphone &&
            css`
                ${theme.mediaQueries.landscapeMobile} {
                    top: 90%;
                }
            `}
        `
    }}
`
