import {ProgressBar} from '@/components/commons/progress-bar/ProgressBar'
import {StyledOverlay} from '../modal/style'
import {FC} from 'react'
import {StyledProgressLoaderContainer} from './style'

type ProgressLoaderProps = {
    value?: number | undefined
}

export const ProgressLoader: FC<ProgressLoaderProps> = ({value}) => (
    <>
        <StyledOverlay />
        <StyledProgressLoaderContainer gap={4} align={'center'}>
            <ProgressBar value={value} />
            <p className={'value'}>{value}%</p>
        </StyledProgressLoaderContainer>
    </>
)

ProgressLoader.displayName = 'ProgressLoader'
