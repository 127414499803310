import {useAuthStore} from '@/store/useAuthStore.tsx'
import {routes, USER_ROLES} from '@utilities/constants'
import {Navigate, Outlet} from 'react-router-dom'

export const AdminRoute = () => {
    const {accessToken, user} = useAuthStore()
    const isUserAdmin = user?.type === USER_ROLES.admin

    if (!accessToken) return <Navigate to={routes.LOGIN.path} />
    return <>{isUserAdmin ? <Outlet /> : <Navigate to={routes.HOME.path} />}</>
}
