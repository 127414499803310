import {TableColumn} from '@components/commons/table/Table.tsx'
import {RequestType, TranslateFunction} from '@/features/signup-requests/types.ts'
import dayjs from '@/dayjs.ts'
import {CounterCredits} from '@/features/signup-requests/components/requests-status/approved/CounterCredits.tsx'
import {truncateText} from '@utilities/helpers.ts'

export const columnsApprovedTable = (isMobile: boolean, t: TranslateFunction) => {
    return [
        {
            label: t('requests:table:header:email'),
            width: '60%',
            cellRender: data => <p className={'approved-email'}>{truncateText(data?.email, isMobile ? 15 : 50)}</p>
        },
        {
            label: t('requests:table:header:approved_date'),
            width: '20%',
            sortName: 'date',
            cellRender: data => <p>{dayjs(data?.approvedAt).format('DD/MM/YYYY')}</p>
        },
        {
            label: t('requests:table:header:credits'),
            width: '20%',
            alignment: 'center',
            cellRender: data => {
                return <CounterCredits data={data} />
            }
        }
    ] as Array<TableColumn<RequestType[], undefined>>
}
