import {UploadPdf} from '@/features/projects/components/create-project/UploadPdf'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useTranslation} from 'react-i18next'
import {ProjectsTable} from '@/features/projects/components/projects-table/ProjectsTable.tsx'
import {StyledContainerElaborationHeader, StyledTitle} from '@/features/projects/components/projects-table/style.ts'
import {InputText} from '@/components/commons/input-text/InputText'
import {SearchLgIcon} from '@/components/ui/icon'
import {ChangeEvent, useMemo, useState} from 'react'
import {DeleteFileModal} from '@/features/projects/components/create-project/uploader-modals/DeleteFileModal'
import {useGetProjects} from '@/features/projects/queries/useGetProjects.ts'
import {SORT_BY} from '@utilities/constants'
import {orderDirection} from '@/features/signup-requests/types.ts'
import {Project} from '@/features/projects/types.ts'

export const Home = () => {
    const {t} = useTranslation()
    const [projectToDelete, setProjectToDelete] = useState<number | undefined>()
    const [orderBy, setSortBy] = useState(SORT_BY.desc)
    const {data, isLoading, isError, error} = useGetProjects(orderBy)
    const [searchValue, setSearchValue] = useState('')

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value
        setSearchValue(searchValue)
    }

    const filteredData = useMemo(() => {
        if (!searchValue) {
            return data?.data
        }
        return data?.data.filter((item: Project) => {
            return item.title?.toLowerCase().includes(searchValue?.toLowerCase())
        })
    }, [searchValue, data])

    return (
        <PageLayout>
            <UploadPdf />
            <StyledContainerElaborationHeader direction={'column'} gap={4}>
                <StyledTitle>{t('upload_pdf:table_title')}</StyledTitle>
                <InputText
                    type={'text'}
                    placeholder={t('commons:search')}
                    typeIcon={<SearchLgIcon size={18} />}
                    className="input-search"
                    onChange={handleSearch}
                />
            </StyledContainerElaborationHeader>
            <ProjectsTable
                setProjectToDelete={setProjectToDelete}
                projectData={filteredData}
                isLoading={isLoading}
                isError={isError}
                error={error}
                sorter={{
                    orderBy: 'date',
                    orderDirection: orderBy as orderDirection,
                    onSort: () => setSortBy(orderBy === SORT_BY.asc ? SORT_BY.desc : SORT_BY.asc)
                }}
            />

            {projectToDelete && (
                <DeleteFileModal
                    projectToDelete={projectToDelete}
                    setProjectToDelete={setProjectToDelete}
                    onClose={() => setProjectToDelete(undefined)}
                />
            )}
        </PageLayout>
    )
}

Home.displayName = 'Home'
