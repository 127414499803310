import * as pages from '@/pages'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    SIGNUP: {
        path: '/signup',
        element: <pages.SignUp />
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        element: <pages.ChangePassword />
    },
    HOME: {
        path: '/',
        element: <pages.Home />
    },
    PROJECT_DETAILS: {
        path: 'project/:id/jobs',
        element: <pages.ProjectDetail />
    },
    SIGNUP_REQUESTS: {
        path: '/admin',
        element: <pages.SignupRequests />
    }
}
