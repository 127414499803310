import styled, {css} from 'styled-components'

export const StyledPrivateLayout = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`

export const StyledMain = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        overflow-y: auto;

        ${mediaQueries.m} {
            height: 100%;
        }
    `}
`
