import toast from 'react-hot-toast'
import {AxiosError} from 'axios'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() === value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() === option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

export const bytesToSize = (bytes: number) => {
    const kb = bytes / 1024
    if (kb < 1024) {
        return `${Number(kb.toFixed(2))}KB`
    } else {
        const mb = kb / 1024
        return `${Number(mb.toFixed(2))}MB`
    }
}

export const convertInMB = (bytes: number) => {
    const kb = bytes / 1024
    const mb = kb / 1024
    return Number(mb.toFixed(2))
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const removeAtIndex = <T>(array: T[], index: number): T[] => {
    return array.filter((_, i) => i !== index)
}

export const createFormData = (files: File[]): FormData => {
    const formData = new FormData()
    files.forEach((file, index) => {
        formData.append(`files[${index}]`, file)
    })
    return formData
}

export const truncateText = (text: string, maxLength: number) => {
    if (text?.length > maxLength) {
        return text.slice(0, maxLength) + '...'
    }
    return text
}

export const truncateTextBeforeExt = (text: string) => {
    if (text.length <= 6) {
        return text
    }
    const firstThree = text.slice(0, 3)
    const lastThree = text.slice(-3)
    const extension = text.slice(-4)
    return `${firstThree}...${lastThree}${extension}`
}

type handleGenericErrorProps = {
    error: AxiosError
    t: (key: string) => string
}
export const handleGenericError = ({error, t}: handleGenericErrorProps) => {
    if (error) {
        const errorObject = error.response?.data as {message: string; code: string; errors: {message: string}[]}
        if (typeof error === 'object' && errorObject?.message) {
            return toast.error(t(`toast:${errorObject?.code}`))
        } else if (errorObject?.errors) {
            return errorObject?.errors?.forEach(e => toast.error(t(`toast:${e.message}`)))
        } else {
            return toast.error(t('errors:default'))
        }
    }
}
