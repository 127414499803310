import axios from '@services/axiosInstance.ts'
import {
    ChangePasswordPayload,
    LoginFormData,
    LoginResponseType,
    PasswordRecoveryPayload,
    SignupDataType
} from '@/features/auth/type.ts'

export const AUTH_API = {
    login: (values: LoginFormData) => axios.post<LoginResponseType>('auth/login', values),
    signup: (values: SignupDataType) => axios.post<LoginResponseType>('/signup-requests', values),
    logout: () => axios.post('auth/logout'),
    passwordRecovery: (values: PasswordRecoveryPayload) => axios.post('/password-resets', values),
    changePassword: (values: ChangePasswordPayload) => axios.post('/change-password', values),
    me: () => axios.get('/me').then(res => res.data)
}
