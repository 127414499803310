import {TableColumn} from '@components/commons/table/Table.tsx'
import {z} from 'zod'

export type RequestType = {
    id: number
    email: string
    createdAt: string
    deletedAt: string
    approvedAt: string
    maxFileUpload: number
    user: {
        fileUploaded: number
    }
}

export type RequestTypeWithStatus = RequestType & {
    status: string
}

export type TranslateFunction = (key: string) => string

export type ColumnPendingTableProps = {
    t: TranslateFunction
    onSelect: (id: number) => void
    selectedIds: number[]
    handleActionRequest: (request: RequestTypeWithStatus) => void
    isMobile?: boolean
}

export type ColumnPendingTable = TableColumn<RequestType[], undefined> & {
    cellRender: (data: RequestType) => JSX.Element
}

export type actionMultipleRequestsPayload = {
    signupRequestsIds: number[]
    status: string
}

export const ServiceDetailsSearchParams = z.object({
    status: z.enum(['pending', 'approved', 'rejected']).optional()
})

export type orderDirection = 'asc' | 'desc' | undefined

export type sorterType = {
    orderBy: string
    orderDirection: orderDirection
    onSort: () => void
}

export type SignUpRequestsProps = {
    data: RequestType[]
    isLoading: boolean
    sorter: sorterType
    isMobile: boolean
    selectedIds: number[]
    setSelectedIds: (ids: number[]) => void
}
export type AdminConfigItem = [
    {
        id: number
        name: string
        isActive: boolean
        createdAt: string
        updatedAt: string
    }
]
