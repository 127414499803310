import {Container} from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'

export const StyledBackground = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        ${mediaQueries.m} {
            background: ${palette.neutral['100']};
            padding: ${spacing * 4}px 0;
        }
    `}
`
export const StyledPageContainer = styled(Container)(
    ({theme: {spacing, palette, mediaQueries}}) => css`
        padding: ${spacing * 6}px;
        ${mediaQueries.m} {
            background: ${palette.neutral['white']};
            border-radius: ${spacing * 2}px;
            width: 100vw;
            display: flex;
            flex-direction: column;
            gap: ${spacing * 6}px;
        }
    `
)

export const StyledWrapperContent = styled.div`
    ${({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            flex: 1;
            overflow: hidden;
            ::-webkit-scrollbar {
                display: none;
            }
        }
    `}
`
