import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Checkbox} from '@components/commons/checkbox/CheckBox.tsx'
import {StyledButtonToAccept} from '@/features/signup-requests/components/requests-status/style.ts'
import {ColumnPendingTable, ColumnPendingTableProps} from '@/features/signup-requests/types.ts'
import dayjs from '@/dayjs.ts'
import {SIGNUP_UP_STATUS} from '@utilities/constants'
import {CheckIcon, XCloseIcon} from '@components/ui/icon'

export const columnsPendingTable = ({
    t,
    onSelect,
    selectedIds,
    handleActionRequest,
    isMobile
}: ColumnPendingTableProps): ColumnPendingTable[] =>
    [
        {
            label: t('requests:table:header:email'),
            width: '50%',
            cellRender: data => (
                <Flexbox align="center" gap={4}>
                    <Checkbox
                        id={data?.id.toString()}
                        onChange={() => onSelect(data?.id)}
                        checked={selectedIds.includes(data?.id)}
                    />
                    <p className={'email'}>{data?.email}</p>
                </Flexbox>
            )
        },
        {
            label: t('requests:table:header:request_date'),
            width: '20%',
            sortName: 'date',
            cellRender: data => <p>{dayjs(data?.createdAt).format('DD/MM/YYYY')}</p>
        },
        {
            label: '',
            alignment: 'right',
            width: '30%',
            cellRender: data => (
                <>
                    {isMobile ? (
                        <Flexbox gap={2} justify="flex-end" width="100%">
                            <XCloseIcon
                                cursor="pointer"
                                fill={'#D92D20'}
                                onClick={() =>
                                    handleActionRequest({
                                        ...data,
                                        status: SIGNUP_UP_STATUS.rejected
                                    })
                                }
                            />
                            <CheckIcon
                                cursor="pointer"
                                onClick={() =>
                                    handleActionRequest({
                                        ...data,
                                        status: SIGNUP_UP_STATUS.approved
                                    })
                                }
                            />
                        </Flexbox>
                    ) : (
                        <Flexbox gap={2} justify="flex-end" width="100%">
                            <Button
                                variant="link"
                                onClick={() =>
                                    handleActionRequest({
                                        ...data,
                                        status: SIGNUP_UP_STATUS.rejected
                                    })
                                }
                            >
                                {t('requests:table:body:reject_request')}
                            </Button>
                            <StyledButtonToAccept
                                variant={isMobile ? 'link' : 'green'}
                                onClick={() =>
                                    handleActionRequest({
                                        ...data,
                                        status: SIGNUP_UP_STATUS.approved
                                    })
                                }
                            >
                                {t('requests:table:body:approve_request')}
                            </StyledButtonToAccept>
                        </Flexbox>
                    )}
                </>
            )
        }
    ] as ColumnPendingTable[]
