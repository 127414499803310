import {useAuthStore} from '@/store/useAuthStore.tsx'
import {routes, USER_ROLES} from '@utilities/constants'
import {Navigate, Outlet} from 'react-router-dom'

export const UserRoute = () => {
    const {accessToken, user} = useAuthStore()
    const isUser = user?.type === USER_ROLES.user

    if (!accessToken) return <Navigate to={routes.LOGIN.path} />
    return <>{isUser ? <Outlet /> : <Navigate to={routes.SIGNUP_REQUESTS.path} />}</>
}
