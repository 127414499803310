import axios from '@services/axiosInstance.ts'
import {
    actionMultipleRequestsPayload,
    AdminConfigItem,
    RequestTypeWithStatus
} from '@/features/signup-requests/types.ts'

export const SIGN_UP_REQUESTS_API = {
    getSignUpRequests: (status: string, orderBy: string) =>
        axios
            .get(`/signup-requests`, {params: {status: status ? status : 'pending', orderBy}})
            .then(response => response.data),

    actionSingleRequest: (requestData: RequestTypeWithStatus) =>
        axios.patch(`/signup-requests/${requestData.id}`, requestData).then(response => response.data),

    actionMultipleRequests: (requestData: actionMultipleRequestsPayload) =>
        axios.patch(`/signup-requests`, requestData).then(response => response.data),

    adminConfig: (): Promise<AdminConfigItem> => axios.get('/admin-configs').then(response => response.data),

    switchAutoApprove: (payload: {status: boolean; userID: number}) =>
        axios.patch(`/admin-configs/${payload?.userID}`, {isActive: payload?.status}).then(response => response.data),

    updateCredits: (payload: {id: number; credits: number}) =>
        axios
            .patch(`/signup-requests/${payload.id}/max-file-upload`, {maxFileUpload: payload.credits})
            .then(response => response.data)
}
