import {FC, useState} from 'react'
import {StyledCardWrapper} from './style'
import {AccordionDetails} from '@/components/commons/accordion/Accordion'
import {InfoCard} from '@/components/commons/card/Card'

type AccordionCardProps = {
    title: string
    status: boolean
    description: string
}

export const AccordionCard: FC<AccordionCardProps> = ({title, status, description}) => {
    const [open, setOpen] = useState(false)

    return (
        <StyledCardWrapper>
            <InfoCard status={status} title={title} />
            <AccordionDetails description={description} open={open} setOpen={setOpen} />
        </StyledCardWrapper>
    )
}
