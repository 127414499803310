import {StateCreator} from 'zustand'
import {AuthStoreActions, AuthStoreState} from '@/store/slices/types.ts'
import {AuthState} from '@/features/auth/type.ts'

const initialState = {
    user: null,
    accessToken: '',
    refreshToken: '',
    isAutoApproveEnabled: false
} satisfies AuthStoreState

export const createAuthDataSlice: StateCreator<AuthState & AuthStoreActions> = set => ({
    ...initialState,
    setAuthData: (key, value) => set(() => ({[key]: value}))
})
