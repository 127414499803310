import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledHeaderWrapper = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        & > h4 {
            ${typography.textXl};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textMd};
        }
    `}
`

export const StyledContainerButtonRecoveryModal = styled(Flexbox)`
    ${({theme: {typography, spacing}}) => css`
        padding-top: ${spacing * 3}px;
        & > button {
            ${typography.textLg};
        }
    `}
`
