import {StyledTableWrapper} from '@/features/signup-requests/components/requests-status/style.ts'
import {Table} from '@components/commons/table/Table.tsx'
import {columnsRejectedTable} from '@/features/signup-requests/components/requests-status/rejected/columnsRejectedTable.tsx'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {RequestTypeWithStatus, SignUpRequestsProps} from '@/features/signup-requests/types.ts'
import {useActionSingleRequest} from '@/features/signup-requests/queries/useActionSingleRequest.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const RejectedRequests: FC<SignUpRequestsProps> = ({
    data,
    isLoading,
    sorter,
    isMobile,
    selectedIds,
    setSelectedIds
}) => {
    const {t} = useTranslation()
    const actionSingleRequest = useActionSingleRequest()

    const onSelect = (id: number) => {
        // Check if the ID is already selected
        const index = selectedIds.indexOf(id)
        if (index === -1) {
            // If not selected, add it to the array
            setSelectedIds([...selectedIds, id])
        } else {
            // If already selected, remove it from the array
            const updatedIds = [...selectedIds]
            updatedIds.splice(index, 1)
            setSelectedIds(updatedIds)
        }
    }

    const handleActionRequest = async (request: RequestTypeWithStatus) => {
        await actionSingleRequest.mutateAsync(request)
        setSelectedIds([])
    }

    return (
        <>
            {actionSingleRequest.isPending && <Spinner />}
            <StyledTableWrapper>
                <Table
                    sorter={sorter}
                    columns={columnsRejectedTable({t, onSelect, selectedIds, handleActionRequest})}
                    data={data}
                    isLoading={isLoading}
                    isMobile={isMobile}
                />
            </StyledTableWrapper>
        </>
    )
}
