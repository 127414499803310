import styled, {css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    ${({theme: {spacing, shadows}}) => css`
        padding: ${spacing * 2}px;
        border-radius: ${spacing * 2}px;
        box-shadow: ${shadows.sm};
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: ${spacing * 4}px;
    `}
`
