import {StyledContainerProgress, StyledProgress} from './style'
import {FC, useEffect, useState} from 'react'

type ProgressBarProps = {
    value: number | undefined
}

export const ProgressBar: FC<ProgressBarProps> = ({value}) => {
    const [progressValue, setProgressValue] = useState(0)

    useEffect(() => {
        if (value) {
            setProgressValue(value)
        }
    }, [value])
    return (
        <StyledContainerProgress>
            <StyledProgress value={progressValue} max={100} />
        </StyledContainerProgress>
    )
}

ProgressBar.displayName = 'ProgressBar'
