import {z} from 'zod'

export const ChangePasswordFormSchema = z
    .object({
        password: z.string().min(8, {message: 'errors:password'}),
        passwordConfirmation: z.string().min(8, {message: 'errors:password'})
    })
    .refine(data => data.password === data.passwordConfirmation, {
        message: 'errors:password_match',
        path: ['passwordConfirmation']
    })

export type ChangePasswordValidationSchema = z.infer<typeof ChangePasswordFormSchema>
