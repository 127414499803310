import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/ko'
import 'dayjs/locale/ar'

export const formatDate = (date: string, formatType = 'YYYY-MM-DD') => (date ? dayjs(date).format(formatType) : '-')
export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()
export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
}

export default dayjs
