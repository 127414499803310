import {FC, PropsWithChildren} from 'react'
import {StyledBackground, StyledPageContainer, StyledWrapperContent} from '@layouts/page-layout/style.ts'

export const PageLayout: FC<PropsWithChildren> = ({children}) => {
    return (
        <StyledBackground>
            <StyledPageContainer>
                <StyledWrapperContent>{children}</StyledWrapperContent>
            </StyledPageContainer>
        </StyledBackground>
    )
}
