import {useMutation, useQueryClient} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {MUTATION_KEYS, QUERY_KEYS} from '../keys.ts'
import {PROJECTS_API} from '@/features/projects/services/projects.http.ts'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import {useTranslation} from 'react-i18next'

export const useDeleteProject = () => {
    const queryClient = useQueryClient()
    const {t} = useTranslation()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_PROJECT],
        mutationFn: PROJECTS_API.deleteProject,
        onSuccess: () => {
            toast.success(t('commons:operation_completed'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
