import {InputText} from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertRedCircleIcon} from '@/components/ui/icon'
import {Dispatch, FC, SetStateAction} from 'react'
import {zodResolver} from '@hookform/resolvers/zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Button} from '@/components/ui/button/Button'
import {StyledContainerButtonRecoveryModal, StyledHeaderWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'
import {PasswordRecoveryPayload} from '@/features/auth/type.ts'
import {PasswordRecoverySchema} from '@/features/auth/schema.ts'
import {handleGenericError} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'

type PasswordRecoveryForm = {
    primaryAction: () => void
    secondaryAction: () => void
    setIsLoading: Dispatch<SetStateAction<boolean>>
    isLoading: boolean
}

export const PasswordRecoveryForm: FC<PasswordRecoveryForm> = ({primaryAction, secondaryAction, setIsLoading}) => {
    const {t} = useTranslation()

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting, isValid}
    } = useForm<PasswordRecoveryPayload>({
        mode: 'onChange',
        resolver: zodResolver(PasswordRecoverySchema)
    })

    const onSubmit: SubmitHandler<PasswordRecoveryPayload> = async values => {
        try {
            setIsLoading(true)
            await AUTH_API.passwordRecovery(values)
            primaryAction()
        } catch (e) {
            const error = e as AxiosError
            handleGenericError({error, t})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Flexbox
                direction={'column'}
                gap={5}
                padding={6}
                as={'form'}
                justify={'center'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <AlertRedCircleIcon size={48} viewBox="0 0 48 48" />

                <StyledHeaderWrapper direction={'column'} gap={2}>
                    <h4>{t('recovery_password:title')}</h4>
                    <p>{t('recovery_password:subtitle')}</p>
                </StyledHeaderWrapper>

                <InputText
                    type={'email'}
                    label={t('form:email_label')}
                    placeholder={t('form:email_placeholder')}
                    errorMessage={t(errors.email?.message || '')}
                    {...register('email')}
                />

                <StyledContainerButtonRecoveryModal gap={3} width={'100%'}>
                    <Button fullWidth variant={'secondary'} onClick={secondaryAction}>
                        {t('commons:cancel')}
                    </Button>
                    <Button fullWidth variant={'green'} type={'submit'} disabled={isSubmitting || !isValid}>
                        {t('commons:confirm')}
                    </Button>
                </StyledContainerButtonRecoveryModal>
            </Flexbox>
        </>
    )
}

PasswordRecoveryForm.displayName = 'PasswordRecoveryForm'
