import styled, {css} from 'styled-components'

export const StyledStepWrapper = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        display: none;
        width: 100%;
        grid-auto-flow: column;

        & > h4 {
            ${typography.textMd};
            font-weight: 700;
        }

        & > p {
            ${typography.textSm};
        }

        ${mediaQueries.m} {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    `}
`

export const StyledTextWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        text-align: center;
        color: ${palette.neutral[700]};

        h4 {
            ${typography.textMd};
            margin-bottom: ${spacing}px;
            font-weight: 700;
        }

        p {
            ${typography.textSm};
        }
    `}
`

export const StyledUploadTitle = styled.h4`
    ${({theme}) => css`
        font-size: 26px;
        color: ${theme.palette.neutral['700']};
        font-weight: 700;
        margin-bottom: ${theme.spacing * 8}px;

        ${theme.mediaQueries.m} {
            ${theme.typography.displayLg};
        }
    `}
`
