import styled, {css, DefaultTheme} from 'styled-components'

type StyledScoreBannerProps = {
    score: 'danger' | 'success' | 'default'
}

const ColorStyles = ({palette}: DefaultTheme) => ({
    danger: css`
        background-color: ${palette.danger[50]};
        color: ${palette.danger[600]};
    `,
    success: css`
        background-color: ${palette.success[50]};
        color: ${palette.success[600]};
    `,

    default: css`
        background-color: ${palette.neutral[50]};
        color: ${palette.danger[500]};
    `
})

export const StyledScoreBannerWrapper = styled.div<StyledScoreBannerProps>`
    ${({theme: {mediaQueries, spacing, palette, typography, shadows}}) => css`
        padding: ${spacing * 2}px;
        box-shadow: ${shadows.sm};
        font-weight: 700;
        width: 100%;
        margin-bottom: ${spacing * 6}px;

        & h4 {
            color: ${palette.neutral[900]};
            ${typography.textMd}
        }

        & p {
            ${typography.textLg}
        }

        ${mediaQueries.m} {
            padding: ${spacing * 4}px ${spacing * 2}px;
        }
    `}

    ${props => ColorStyles(props.theme)[props.score]}
`
