import styled, {css, DefaultTheme} from 'styled-components'

export const StyledDetailsTableContainer = styled.div`
    ${({theme: {mediaQueries}}) => css`
        height: calc(100vh - 300px);
        ${mediaQueries.m} {
            width: 100%;
            height: calc(100vh - 340px);
        }

        ${mediaQueries.landscapeMobile} {
            height: 100vh;
        }
    `}
`
export const StyledMobileTextContainer = styled.div`
    ${({theme: {palette}}) => css`
        font-weight: bold;
        color: ${palette.neutral[800]};
        & span {
            font-weight: 400;
            color: ${palette.neutral[500]};
        }
    `}
`

export const StyledScore = styled.span`
    ${({theme, score}: {theme: DefaultTheme; score: number}) => css`
        font-weight: 700 !important;
        color: ${score === 0
            ? theme.palette.neutral.black
            : score < 50
              ? theme.palette.danger[500]
              : theme.palette.green[500]} !important;
    `}
`
