import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {StyledSignUpFormWrapper} from '@pages/sign-up/style.ts'
import {StyledLoginHeaderWrapper} from '@pages/login/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    ChangePasswordFormSchema,
    ChangePasswordValidationSchema
} from '@pages/change-password/changePasswordFormSchema.ts'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'
import {useLocation} from 'react-router-dom'
import {ModalPasswordUpdated} from '@/features/auth/components/modal-password-updated/ModalPasswordUpdated.tsx'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'

export const ChangePassword = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [openSuccessModalToLogin, setOpenSuccessModalToLogin] = useState(false)

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting, isValid}
    } = useForm<ChangePasswordValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(ChangePasswordFormSchema)
    })

    const onSubmit: SubmitHandler<ChangePasswordValidationSchema> = async values => {
        setIsLoading(true)
        try {
            const valuesBody = {
                token: location.search.replace('?token=', ''),
                password: values.password,
                passwordConfirmation: values.passwordConfirmation
            }
            await AUTH_API.changePassword(valuesBody)
            setOpenSuccessModalToLogin(true)
        } catch (e) {
            const error = e as AxiosError
            handleGenericError({error, t})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {isLoading && <Spinner />}
            <StyledSignUpFormWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledLoginHeaderWrapper align={'center'} direction={'column'} gap={2}>
                    <h4>{t('change_password:title')}</h4>
                    <p>{t('change_password:subtitle')}</p>
                </StyledLoginHeaderWrapper>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <InputText
                        type={'password'}
                        label={t('form:new_password_label')}
                        placeholder={t('form:new_password_placeholder')}
                        errorMessage={t(errors.password?.message || '')}
                        {...register('password')}
                        visibilityToggle
                        inputSize={'sm'}
                    />

                    <InputText
                        type={'password'}
                        label={t('form:confirm_new_password_label')}
                        placeholder={t('form:confirm_new_password_placeholder')}
                        errorMessage={t(errors.passwordConfirmation?.message || '')}
                        {...register('passwordConfirmation')}
                        visibilityToggle
                        inputSize={'sm'}
                    />
                </Flexbox>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <Button variant={'green'} type={'submit'} size={'sm'} fullWidth disabled={isSubmitting || !isValid}>
                        {t('change_password:cta')}
                    </Button>
                </Flexbox>
            </StyledSignUpFormWrapper>
            {openSuccessModalToLogin && <ModalPasswordUpdated />}
        </>
    )
}
