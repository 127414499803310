import React, {useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledButtonLink, StyledLink, StyledLoginHeaderWrapper, StyledNoAccountParagraph} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {LoginFormSchema, LoginValidationSchema} from './LoginFormSchema'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN, routes} from '@/utilities/constants'
import {EmailIcon} from '@components/ui/icon'
import {PasswordRecoveryModal} from '@/features/auth/components/password-recovery-modal/PasswordRecoveryModal.tsx'
import {StyledSignUpFormWrapper} from '@pages/sign-up/style.ts'
import {useAuthStore} from '@/store/useAuthStore.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useLogin} from '@/features/auth/queries/useLogin.ts'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const [openRecoveryModal, setOpenRecoveryModal] = useState(false)
    const login = useLogin()

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting, isValid}
    } = useForm<LoginValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(LoginFormSchema)
    })

    const onSubmit: SubmitHandler<LoginValidationSchema> = async values => {
        const {data} = await login.mutateAsync(values)
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, data.accessToken)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken)
        useAuthStore.setState(() => ({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            user: data.user
        }))
    }

    return (
        <>
            {login.isPending && <Spinner />}
            <StyledSignUpFormWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledLoginHeaderWrapper align={'center'} direction={'column'} gap={2}>
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                </StyledLoginHeaderWrapper>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <InputText
                        type={'email'}
                        label={t('form:email_label')}
                        placeholder={t('form:email_placeholder')}
                        errorMessage={t(errors.email?.message || '')}
                        {...register('email')}
                        inputSize={'sm'}
                        typeIcon={<EmailIcon fill={'white'} size={20} viewBox="0 0 20 20" />}
                    />

                    <InputText
                        type={'password'}
                        label={t('form:password_label')}
                        placeholder={t('form:password_placeholder')}
                        errorMessage={t(errors.password?.message || '')}
                        {...register('password')}
                        visibilityToggle
                        inputSize={'sm'}
                    />

                    <Flexbox align={'center'} justify={'flex-end'} width={'100%'}>
                        <StyledButtonLink onClick={() => setOpenRecoveryModal(true)}>
                            <p>{t('login:forgot_password')}</p>
                        </StyledButtonLink>
                    </Flexbox>
                </Flexbox>

                <Flexbox className={'input-wrapper'} direction={'column'} align={'flex-start'} gap={4}>
                    <Button variant={'green'} type={'submit'} size={'sm'} fullWidth disabled={isSubmitting || !isValid}>
                        {t('login:cta')}
                    </Button>

                    <Flexbox gap={4} width={'360px'} justify={'center'} align={'flex-end'}>
                        <StyledNoAccountParagraph>{t('login:no_account')}</StyledNoAccountParagraph>
                        <StyledLink to={routes.SIGNUP.path}>{t('login:signup')}</StyledLink>
                    </Flexbox>
                </Flexbox>
            </StyledSignUpFormWrapper>
            {openRecoveryModal && <PasswordRecoveryModal onClose={() => setOpenRecoveryModal(false)} />}
        </>
    )
}

Login.displayName = 'Login'
