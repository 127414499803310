import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '../keys'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'

export const useGetMe = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.ME],
        queryFn: () => AUTH_API.me()
    })
}
