import {useTranslation} from 'react-i18next'
import {StyledStepWrapper, StyledTextWrapper, StyledUploadTitle} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckFileIcon, ProcessingDataIcon, UploadIcon} from '@/components/ui/icon'
import {FileUploader} from './file-uploader/FileUploader'
import {useMemo, useState} from 'react'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useGetMe} from '@/features/projects/queries/useGetMe.ts'

export const UploadPdf = () => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 500, [width])
    const [uploadedFile, setUploadedFile] = useState<File[] | null>(null)
    const [uploadModal, setUploadModal] = useState(false)
    const {data: userMe} = useGetMe()
    const fileAlreadyUploaded = useMemo(() => userMe?.fileUploaded ?? 0, [userMe])
    const maxFiles = useMemo(() => userMe?.maxFileUploaded ?? 10, [userMe])
    const acceptedFiles = {'text/pdf': ['.pdf']}
    const maxSizeInMB = 400

    return (
        <>
            <StyledUploadTitle>{t('upload_pdf:title')}</StyledUploadTitle>
            <Flexbox direction={isMobile ? 'column-reverse' : 'column'} width={'100%'} gap={6}>
                <StyledStepWrapper>
                    <Flexbox direction="column" align="center" gap={2}>
                        <UploadIcon viewBox="0 0 30 30" size={30} />
                        <StyledTextWrapper>
                            <h4>{t('upload_pdf:header_step:first:title')}</h4>
                            <p>{t('upload_pdf:header_step:first:subtitle')}</p>
                        </StyledTextWrapper>
                    </Flexbox>
                    <Flexbox direction="column" align="center" gap={2}>
                        <ProcessingDataIcon viewBox="0 0 30 30" size={30} />
                        <StyledTextWrapper>
                            <h4>{t('upload_pdf:header_step:second:title')}</h4>
                            <p>{t('upload_pdf:header_step:second:subtitle')}</p>
                        </StyledTextWrapper>
                    </Flexbox>
                    <Flexbox direction="column" align="center" gap={2}>
                        <CheckFileIcon viewBox="0 0 30 30" size={30} />
                        <StyledTextWrapper>
                            <h4>{t('upload_pdf:header_step:third:title')}</h4>
                            <p>{t('upload_pdf:header_step:third:subtitle')}</p>
                        </StyledTextWrapper>
                    </Flexbox>
                </StyledStepWrapper>
                <FileUploader
                    acceptedFiles={acceptedFiles}
                    maxSizeInMB={maxSizeInMB}
                    fileAlreadyUploaded={fileAlreadyUploaded}
                    maxFiles={maxFiles}
                    onAcceptFiles={() => uploadedFile}
                    uploadedFile={uploadedFile || null}
                    setUploadedFile={setUploadedFile}
                    uploadModal={uploadModal}
                    setUploadModal={setUploadModal}
                />
            </Flexbox>
        </>
    )
}

UploadPdf.displayName = 'UploadPdf'
