import {StyledSorterContainer, StyledTHead} from '@/components/commons/table/mobile-t-head/style'
import {TableColumn, TableSorter} from '@components/commons/table/Table.tsx'
import {ChevronDownIcon, ChevronUpIcon} from 'src/components/ui/icon'
import {MouseEvent} from 'react'
import {useTranslation} from 'react-i18next'

interface MobileTHeadProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    sorter: TableSorter<TSortName>
}

export const MobileTHead = <TData extends unknown[], TSortName extends string | undefined>({
    sorter
}: MobileTHeadProps<TData, TSortName>) => {
    const {t} = useTranslation()

    const changeSortDirection = (event: MouseEvent) => {
        event.stopPropagation()
        const nextSortDirection = sorter.orderDirection == 'desc' ? 'asc' : 'desc'
        sorter.onSort({...sorter, orderDirection: nextSortDirection})
    }

    return (
        <StyledTHead>
            <StyledSorterContainer onClick={changeSortDirection}>
                {sorter.orderDirection == 'asc' ? (
                    <>
                        {t('upload_pdf:table:date')}
                        <ChevronUpIcon size={16} />
                    </>
                ) : sorter.orderDirection == 'desc' ? (
                    <>
                        {t('upload_pdf:table:date')}
                        <ChevronDownIcon size={16} />
                    </>
                ) : null}
            </StyledSorterContainer>
        </StyledTHead>
    )
}

MobileTHead.displayName = 'MobileTHead'
