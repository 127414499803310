import styled, {css} from 'styled-components'

export const StyledEmptySearch = styled('div')(
    ({theme: {spacing, palette, typography}}) => css`
        width: 100%;
        background: ${palette.neutral.white};
        border-radius: 12px;
        border: 1px solid ${palette.neutral['200']};
        padding: ${spacing * 10}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: max-content;

        h3 {
            ${typography.textMd};
            color: ${palette.neutral['700']};
            margin-top: ${spacing * 4}px;
        }
        h4 {
            ${typography.textSm};
            margin: ${spacing}px 0 ${spacing * 6}px;
        }
    `
)
