import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '../keys'
import {PROJECTS_API} from '@/features/projects/services/projects.http.ts'

export const useProjectDetail = (id: number) => {
    return useQuery({
        queryKey: [QUERY_KEYS.JOB, id],
        queryFn: () => PROJECTS_API.getProjectDetail(id)
    })
}
