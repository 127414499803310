import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledPageNotFoundContainer = styled(Flexbox)`
    ${({theme}) => css`
        ${theme.typography.textLg};

        .paragraph {
            font-weight: 700;
            color: ${theme.palette.danger['500']};
        }

        & h3 {
            ${theme.typography.displayMd};
            font-weight: 700;
            color: ${theme.palette.neutral['900']};

            ${theme.mediaQueries.l} {
                ${theme.typography.displayXl};
            }
        }

        .subtitle {
            color: ${theme.palette.neutral['600']};
            padding-bottom: ${theme.spacing * 6}px;
        }
    `}
`
