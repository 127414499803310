import {Button} from '@/components/ui/button/Button'
import {Dropzone} from '@/components/ui/dropzone/Dropzone'

import styled, {css} from 'styled-components'

type StyledUploadAreaWrapperType = {
    uploadedFile: number | null
}
export const StyledUploadAreaWrapper = styled(Dropzone)`
    ${({theme: {spacing, palette, typography, mediaQueries}}) => css`
        width: 100%;
        height: 100%;
        margin-bottom: ${spacing * 4}px;
        border-radius: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${mediaQueries.m} {
            padding: ${spacing * 6}px ${spacing * 4}px;
            border: 1px solid ${palette.green[25]};
            background-color: ${palette.neutral[50]};
            padding: ${spacing * 4}px ${spacing * 2}px;
            margin-bottom: ${spacing * 6}px;
        }
        .explanation-number {
            ${typography.textLg};
            font-weight: 700;
            color: ${palette.neutral['black']};
        }

        .info-icon {
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        }

        & button {
            ${typography.textMd};

            ${mediaQueries.xs} {
                white-space: nowrap;
            }

            ${mediaQueries.m} {
                padding: ${spacing * 3}px ${spacing * 4}px;
                ${typography.textLg};
            }
        }

        & .upload-icon,
        .upload-text-container > p {
            display: none;

            ${mediaQueries.m} {
                display: flex;
            }
        }
    `}
`

export const StyledUploadButton = styled(Button)`
    ${({theme: {palette, typography, spacing}}) => css`
        border: 2px solid ${palette.green[25]};
        border-radius: ${spacing * 2}px;
        ${typography.textLg};
        font-weight: 400;
        color: ${palette.neutral[500]};
    `}
`
export const StyledUploadFileLayout = styled.div<StyledUploadAreaWrapperType>`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.m} {
            width: 90%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: ${spacing * 2}px;
        }
    `}
`

export const StyledUploadingFileWrapper = styled.div`
    ${({theme: {palette, spacing, mediaQueries, typography}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
        border: ${spacing / 2}px solid ${palette.violet[900]};
        background-color: ${palette.violet[25]};
        border-radius: ${spacing * 3}px;
        padding: ${spacing * 2}px ${spacing * 4}px;
        ${typography.textMd};

        ${mediaQueries.m} {
            padding: ${spacing * 2}px ${spacing * 6}px;
        }

        .file-name {
            width: 160px;
            ${typography.textMd};
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            ${mediaQueries.m} {
                ${typography.textLg}
            }
        }

        ${mediaQueries.s} {
            padding: ${spacing * 3}px ${spacing * 6}px;
        }

        ${mediaQueries.l} {
            padding: ${spacing * 3}px ${spacing * 6}px;
        }
    `}
`

export const StyledResultButtonWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        text-align: center;
    `}
`
export const StyledRemainingItems = styled.p`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textMd};
        font-weight: 700;
        color: ${palette.neutral[600]};
        margin: ${spacing}px 0 ${spacing}px;
    `}
`
