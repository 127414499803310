import {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useGetSignUpRequests} from '@/features/signup-requests/queries/useGetSignUpRequests.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {PageLayout} from '@layouts/page-layout/PageLayout.tsx'
import {SearchLgIcon} from '@components/ui/icon'
import {StyledInputText, StyledTitle} from '@pages/signup-requests/styles.ts'
import {PendingRequests} from '@/features/signup-requests/components/requests-status/pending/PendingReuqests.tsx'
import {ApprovedRequests} from '@/features/signup-requests/components/requests-status/approved/ApprovedRequests.tsx'
import {RejectedRequests} from '@/features/signup-requests/components/requests-status/rejected/RejectedRequests.tsx'
import {
    AdminConfigItem,
    orderDirection,
    RequestType,
    ServiceDetailsSearchParams
} from '@/features/signup-requests/types.ts'
import {useGetAdminConfig} from '@/features/signup-requests/queries/useGetAdminConfig.ts'
import Tabs from '@components/commons/tabs/Tabs.tsx'
import {SIGNUP_UP_STATUS, SORT_BY} from '@utilities/constants'
import {REQUESTS_STATUS} from '@pages/signup-requests/types.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import {HeaderRequestsTable} from '@/features/signup-requests/components/requests-header/HeaderRequestsTable.tsx'

export const SIGNUP_TABS_TABS = [
    {
        id: 'pending',
        name: 'requests:tabs:pending'
    },
    {
        id: 'approved',
        name: 'requests:tabs:approved'
    },
    {
        id: 'rejected',
        name: 'requests:tabs:rejected'
    }
]

export const SignupRequests = () => {
    const [orderBy, setSortBy] = useState(SORT_BY.desc)
    const {searchParams, setSearchParams} = useParsedSearchParams([], ServiceDetailsSearchParams)
    const {data: adminConfig, isError: isAdminError, error: adminError} = useGetAdminConfig()
    const isAutoApproveEnabled = adminConfig?.[0]?.isActive
    const {data, isLoading, error, isError, isPending, isFetching} = useGetSignUpRequests(
        searchParams?.status as REQUESTS_STATUS,
        orderBy,
        adminConfig as AdminConfigItem
    )
    const {t} = useTranslation()
    const [searchValue, setSearchValue] = useState('')
    const [selectedIds, setSelectedIds] = useState<number[]>([])

    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 1058, [width])

    if (isError || isAdminError) {
        const errorObject = (error || adminError) as AxiosError
        handleGenericError({error: errorObject, t})
    }

    useEffect(() => {
        if (!searchParams.status) {
            setSearchParams({status: SIGNUP_UP_STATUS.pending as REQUESTS_STATUS})
        }
        if (isAutoApproveEnabled && searchParams.status === SIGNUP_UP_STATUS.pending) {
            setSearchParams({status: SIGNUP_UP_STATUS.approved as REQUESTS_STATUS})
        }
    }, [isAutoApproveEnabled, searchParams.status])

    const handleTabSelect = (tab: string) => {
        setSearchParams({status: tab as REQUESTS_STATUS})
        setSelectedIds([])
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value
        setSearchValue(searchValue)
    }

    const filteredData = useMemo(() => {
        if (!searchValue) {
            return data
        }
        return data?.filter((item: RequestType) => {
            return item.email.toLowerCase().includes(searchValue.toLowerCase())
        })
    }, [searchValue, data])

    const props = {
        data: filteredData,
        isLoading: isLoading || isPending || isFetching,
        sorter: {
            orderBy: 'date',
            orderDirection: orderBy as orderDirection,
            onSort: () => setSortBy(orderBy === SORT_BY.asc ? SORT_BY.desc : SORT_BY.asc)
        },
        isMobile,
        selectedIds,
        setSelectedIds
    }
    const tabs = {
        [SIGNUP_TABS_TABS[0].id]: <PendingRequests {...props} />,
        [SIGNUP_TABS_TABS[1].id]: <ApprovedRequests {...props} />,
        [SIGNUP_TABS_TABS[2].id]: <RejectedRequests {...props} />
    }

    return (
        <PageLayout>
            <StyledTitle>{t('requests:title')}</StyledTitle>
            <StyledInputText
                onChange={handleSearch}
                type="text"
                placeholder={t('commons:search')}
                typeIcon={<SearchLgIcon size={18} />}
                className={'search-input'}
            />
            <Tabs
                tabsList={
                    isAutoApproveEnabled
                        ? SIGNUP_TABS_TABS.filter(tab => tab.id !== SIGNUP_UP_STATUS.pending)
                        : SIGNUP_TABS_TABS
                }
                activeTabId={searchParams.status || SIGNUP_UP_STATUS.pending}
                setActiveTabId={handleTabSelect}
            />
            <HeaderRequestsTable data={filteredData} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
            {tabs[searchParams.status || SIGNUP_UP_STATUS.pending]}
        </PageLayout>
    )
}
