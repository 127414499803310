import styled, {css} from 'styled-components'

export const StyledDate = styled.p`
    ${({theme}) => css`
        ${theme.typography.textSm};
        color: ${theme.palette.neutral['700']};

        ${theme.mediaQueries.m} {
            ${theme.typography.textMd};
        }
    `}
`
export const StyledHeaderContainer = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        margin-bottom: ${spacing * 6}px;

        & h4 {
            max-width: 85vw;
            overflow: hidden;
            text-overflow: ellipsis;
            ${typography.displayMd};
            color: ${palette.neutral['700']};
            white-space: nowrap;
            font-weight: 700;
            margin: ${spacing * 2}px 0 ${spacing * 2}px 0;

            ${mediaQueries.m} {
                width: 90%;
                ${typography.displayLg};
            }
        }

        & .input-search {
            border-radius: ${spacing * 3}px !important;
        }

        & .back-text {
            ${typography.textMd} !important;
            color: ${palette.neutral['700']} !important;
            font-weight: 700 !important;
            cursor: pointer;
        }
    `}
`
