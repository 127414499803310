import {TableColumn, TableExpandedRows} from '@components/commons/table/Table.tsx'
import {
    StyledDesktopTBody,
    StyledTCell,
    StyledTRowWrapper,
    StyledTRow
} from '@/components/commons/table/desktop-t-body/style'
import {DesktopSkeleton} from '@components/commons/table/desktop-skeleton/DesktopSkeleton'
import {EmptyPlaceholder} from '@components/commons/table/empty-placeholder/EmptyPlaceholder'
import {ErrorMessage} from '@/components/commons/table/error-message/ErrorMessage'
import {ReactNode} from 'react'

interface DesktopTBodyProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    data: TData
    isError?: boolean
    expandedRows?: TableExpandedRows<TData>
    isChangingPage?: boolean
    isLoading: boolean
    onChangePage?: (lastItemIndex: number) => void
    subRow?: (item: TData[number], itemIndex: number) => ReactNode
}

export const DesktopTBody = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    data,
    isError,
    expandedRows,
    isChangingPage,
    isLoading,
    onChangePage,
    subRow
}: DesktopTBodyProps<TData, TSortName>) => {
    return (
        <StyledDesktopTBody
            data={data}
            endReached={isError ? undefined : onChangePage} //TODO: check why triggered infinite times if scrollbar is still to bottom
            overscan={100}
            itemContent={(itemIndex, item) => {
                return (
                    <StyledTRowWrapper key={itemIndex}>
                        <StyledTRow>
                            {columns.map((column, index) => (
                                <StyledTCell alignment={column.alignment} width={column.width} key={index}>
                                    <div>{column.cellRender(item, itemIndex)}</div>
                                </StyledTCell>
                            ))}
                        </StyledTRow>
                        {subRow?.(item, itemIndex)}
                        {expandedRows?.indexes?.includes(itemIndex) && expandedRows.render(item, itemIndex)}
                    </StyledTRowWrapper>
                )
            }}
            components={{
                EmptyPlaceholder: () => {
                    if (isLoading) {
                        return <DesktopSkeleton columns={columns} />
                    }
                    if (isError) {
                        return null
                    }

                    return <EmptyPlaceholder />
                },
                Footer: () => {
                    if (isLoading) {
                        return null
                    }
                    if (isError) {
                        return <ErrorMessage />
                    }
                    if (isChangingPage) {
                        return <DesktopSkeleton columns={columns} />
                    }

                    return null
                }
            }}
        />
    )
}

DesktopTBody.displayName = 'DesktopTBody'
