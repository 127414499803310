import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledHeaderWrapper = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        text-align: left;
        & > h4 {
            ${typography.textXl};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textMd};
        }
    `}
`

export const StyledDeleteModalWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 4}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        align-self: stretch;
        gap: ${spacing * 6}px;

        ${mediaQueries.s} {
            padding: ${spacing * 6}px;
            flex-direction: row;
            align-items: flex-start;
            text-align: left;
        }
    `}
`

export const StyledModalContainer = styled.form`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
        align-items: center;

        ${mediaQueries.m} {
            align-items: flex-start;
        }

        & button {
            margin-top: ${spacing * 8}px;
        }

        & .confirmButton {
            margin-top: 0;
        }
    `}
`

export const StyledButtonContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 3}px;

        & button {
            margin-top: ${spacing * 8}px;
        }
    `}
`
export const StyledConfirmButton = styled(Button)`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        padding: ${spacing * 2}px ${spacing * 5}px;
        ${typography.textMd};

        ${mediaQueries.m} {
            ${typography.textLg}
        }
    `}
`

export const StyledContainerCloseIcon = styled.div`
    ${() => css`
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    `}
`

export const StyledCancelButton = styled(Button)`
    ${({theme: {palette}}) => css`
        background-color: ${palette.neutral[500]};
        color: ${palette.neutral.white};

        &:hover {
            background-color: ${palette.neutral.white};
            color: ${palette.neutral[700]};
        }
    `}
`

export const StyledDeleteButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.danger[700]};
    `}
`
