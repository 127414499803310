import {AddFileIcon, InfoIcon, UploadCloudIcon, UploadFileIcon, XCloseIcon} from '@/components/ui/icon'
import {Trans, useTranslation} from 'react-i18next'
import {Accept, useDropzone} from 'react-dropzone'
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import {
    StyledRemainingItems,
    StyledResultButtonWrapper,
    StyledUploadAreaWrapper,
    StyledUploadButton,
    StyledUploadFileLayout,
    StyledUploadingFileWrapper
} from './style'
import {megabytesToBytes, removeAtIndex, bytesToSize, convertInMB} from '@/utilities/helpers'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useCreateProject} from '../../../queries/useCreateProject'
import toast from 'react-hot-toast'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {AxiosProgressEvent} from 'axios'
import {ProgressLoader} from '@/components/ui/progress-loader/ProgressLoader'
import {useChangeProjectTitle} from '@/features/projects/queries/useChangeProjectTitle.ts'
import {EditFileNameModal} from '@/features/projects/components/create-project/uploader-modals/EditFileNameModal.tsx'
import {FileType} from '@/features/projects/types.ts'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'

type FileUploaderProps = {
    acceptedFiles: Accept
    maxFiles: number
    maxSizeInMB: number
    onAcceptFiles: (file: File) => void
    uploadedFile: File[] | null
    uploadModal: boolean
    setUploadedFile: Dispatch<SetStateAction<File[] | null>>
    setUploadModal: Dispatch<SetStateAction<boolean>>
    fileAlreadyUploaded: number
}

export const FileUploader: FC<FileUploaderProps> = ({
    acceptedFiles,
    maxFiles,
    maxSizeInMB,
    uploadModal,
    setUploadModal,
    uploadedFile,
    setUploadedFile,
    fileAlreadyUploaded
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])
    const [progress, setProgress] = useState(0)
    const {mutate: changeFileTitle} = useChangeProjectTitle()

    const dropzoneState = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: acceptedFiles,
        onDrop: acceptedFiles => {
            const size = acceptedFiles.map(file => convertInMB(file.size))
            const totalFiles = uploadedFile ? uploadedFile.length + acceptedFiles.length : acceptedFiles.length
            if (size.some(s => s >= 8)) {
                toast.error(t('toast:too_large_file'))
            } else if (totalFiles > maxFiles || fileAlreadyUploaded >= maxFiles) {
                toast.error(t('toast:max_files'))
                return
            } else {
                setUploadedFile(prevUploadedFiles => {
                    if (prevUploadedFiles === null) {
                        return acceptedFiles
                    }
                    return [...prevUploadedFiles, ...acceptedFiles]
                })
            }
        },
        onDropRejected(fileRejections) {
            const type = fileRejections.map(file => file.file.type)
            if (type.some(t => t !== 'application/pdf')) {
                toast.error(t('toast:file_not_supported'))
            } else {
                toast.error(fileRejections[0].errors[0].message)
            }
        },
        maxFiles,
        maxSize: megabytesToBytes(maxSizeInMB)
    })

    const removeUploadedFile = (indexToRemove: number): void => {
        setUploadedFile(prevUploadedFiles => {
            if (prevUploadedFiles === null) {
                return null
            }
            return removeAtIndex(prevUploadedFiles, indexToRemove)
        })
    }

    const {
        data,
        mutate: createProject,
        isPending
    } = useCreateProject({
        onSuccess: () => {
            setUploadedFile(null)
            setUploadModal(true)
            setProgress(0)
        }
    })

    const uploadFileHandler = (files: File[]) => {
        createProject({
            files,
            onProgress: (progressEvent: AxiosProgressEvent) => {
                const total = progressEvent.total || 1
                const loaded = progressEvent.loaded || 0
                const percent = Math.round((loaded / total) * 100)
                setProgress(percent)
            }
        })
    }

    const renameFileTitle = (projectId: number, title: string) => {
        changeFileTitle({
            id: projectId,
            title
        })
        setUploadModal(false)
        setUploadedFile(null)
    }

    const uploadFileLength = uploadedFile?.length || 0

    const disableUploadButton = useMemo(() => {
        return fileAlreadyUploaded >= maxFiles || isPending
    }, [fileAlreadyUploaded, maxFiles, isPending])

    const handleUpload = () => {
        if (disableUploadButton) return
        return dropzoneState.open()
    }

    return (
        <>
            <StyledUploadAreaWrapper state={dropzoneState}>
                {uploadedFile && uploadedFile.length > 0 ? (
                    <>
                        <StyledUploadFileLayout uploadedFile={uploadFileLength}>
                            {uploadedFile.slice(0, isMobile ? 3 : 6).map((item, key) => (
                                <StyledUploadingFileWrapper key={key}>
                                    <UploadFileIcon size={40} viewBox="0 0 37 36" />
                                    <div>
                                        <p className="file-name">{item.name}</p>
                                        <p>{bytesToSize(item.size)}</p>
                                    </div>

                                    <XCloseIcon size={18} onClick={() => removeUploadedFile(key)} cursor={'pointer'} />
                                </StyledUploadingFileWrapper>
                            ))}
                        </StyledUploadFileLayout>

                        {uploadedFile.length > (isMobile ? 3 : 6) && (
                            <StyledRemainingItems>
                                +{uploadedFile.length - (isMobile ? 3 : 6)}
                                {t('upload_pdf:remaining_items')}
                            </StyledRemainingItems>
                        )}
                    </>
                ) : (
                    <>
                        <UploadCloudIcon size={46} viewBox="0 0 46 46" className="upload-icon" />
                        <Flexbox direction="column" align="center" gap={2} className="upload-text-container">
                            <Trans i18nKey={'upload_pdf:area:title'} components={[<p></p>]} />
                            <StyledUploadButton
                                variant="secondary"
                                type="button"
                                onClick={handleUpload}
                                disabled={disableUploadButton}
                            >
                                <p>{t('upload_pdf:area:cta')}</p>
                                <AddFileIcon size={18} viewBox="0 0 17 20" />
                            </StyledUploadButton>
                        </Flexbox>
                    </>
                )}

                <StyledResultButtonWrapper>
                    {uploadedFile && uploadedFile.length > 0 && (
                        <Flexbox align="center" gap={2}>
                            <Button
                                variant={'secondary'}
                                size={'sm'}
                                onClick={handleUpload}
                                disabled={disableUploadButton}
                            >
                                <AddFileIcon size={18} viewBox="0 0 17 20" />
                            </Button>
                            <Button
                                disabled={disableUploadButton}
                                variant={'green'}
                                size={'sm'}
                                onClick={() => {
                                    uploadFileHandler(uploadedFile)
                                }}
                            >
                                <p>{t('upload_pdf:get_results')}</p>
                            </Button>
                        </Flexbox>
                    )}
                </StyledResultButtonWrapper>

                <Flexbox align="center" gap={2} padding={2}>
                    <span className={'explanation-number'}>
                        {fileAlreadyUploaded ?? 0}/{maxFiles ?? 0}
                    </span>
                    <Tooltip
                        variant={'light'}
                        triggerProps={{
                            className: 'info-icon'
                        }}
                        trigger={
                            <InfoIcon
                                size={16}
                                viewBox={'0 0 16 16'}
                                fill={'#fff'}
                                cursor={'pointer'}
                                onClick={e => e.preventDefault()}
                            />
                        }
                        content={t('upload_pdf:files_uploaded', {
                            COUNT: fileAlreadyUploaded ?? 0,
                            MAX: maxFiles ?? 0
                        })}
                    />
                </Flexbox>

                {isPending && <ProgressLoader value={progress} />}

                {uploadModal && (
                    <EditFileNameModal projectData={data} onConfirm={renameFileTitle} type={FileType.file} />
                )}
            </StyledUploadAreaWrapper>
        </>
    )
}

FileUploader.displayName = 'FileUploader'
