import {Table} from '@components/commons/table/Table.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CalendarIcon, ClockIcon, DeleteBucketIcon, EditIcon, EyeIcon} from '@components/ui/icon'
import {TableDataCell} from '@components/commons/table/table-data-cell/TableDataCell.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ChipStatus} from '../chip-status/ChipStatus'
import {formatDate} from '@/dayjs'
import {FileType, Project} from '../../types'
import {useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {StyledProjectsTableContainer} from './style'
import {handleGenericError, truncateTextBeforeExt} from '@/utilities/helpers'
import {AxiosError} from 'axios'
import {sorterType} from '@/features/signup-requests/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {EditFileNameModal} from '@/features/projects/components/create-project/uploader-modals/EditFileNameModal.tsx'
import {useChangeProjectTitle} from '@/features/projects/queries/useChangeProjectTitle.ts'

type ProjectsTableProps = {
    projectData: Project[]
    isLoading: boolean
    isError: boolean
    error: Error | null
    setProjectToDelete: Dispatch<SetStateAction<number | undefined>>
    sorter: sorterType
}

export const ProjectsTable: FC<ProjectsTableProps> = ({
    projectData,
    isLoading,
    isError,
    error,
    setProjectToDelete,
    sorter
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])
    const navigate = useNavigate()
    const [editNameModal, setEditNameModal] = useState({
        isOpen: false,
        projectId: null as number | null
    })
    const {mutate: changeFileTitle} = useChangeProjectTitle()

    if (isError) {
        const errorObject = error as AxiosError
        handleGenericError({error: errorObject, t})
    }

    const openEditNameModal = (id: number) => {
        setEditNameModal({
            isOpen: true,
            projectId: id
        })
    }

    const renameFileTitle = (projectId: number, title: string) => {
        changeFileTitle({
            id: projectId,
            title
        })
        setEditNameModal({
            isOpen: false,
            projectId: null
        })
    }

    return (
        <>
            <StyledProjectsTableContainer>
                <Table
                    data={projectData}
                    isLoading={isLoading}
                    isMobile={isMobile}
                    sorter={sorter}
                    columnsMobile={[
                        {
                            width: '50%',
                            cellRender: data => (
                                <>
                                    <TableDataCell isBold>
                                        <Flexbox align="center" gap={2}>
                                            <ChipStatus status={data?.status} isMobile={isMobile} />
                                            <p>{truncateTextBeforeExt(data?.title)}</p>
                                        </Flexbox>
                                    </TableDataCell>
                                    <Flexbox gap={2}>
                                        <Flexbox gap={2}>
                                            <Flexbox align="center" gap={1}>
                                                <CalendarIcon size={12} viewBox="0 0 12 12" />
                                                {formatDate(data?.createdAt, 'DD/MM/YYYY')}
                                            </Flexbox>
                                            <Flexbox align="center" gap={1}>
                                                <ClockIcon size={12} />
                                                {formatDate(data?.createdAt, 'HH:mm')}
                                            </Flexbox>
                                        </Flexbox>
                                    </Flexbox>
                                </>
                            )
                        },
                        {
                            width: '50%',
                            cellRender: data => (
                                <>
                                    <Flexbox align="center" gap={2}>
                                        <EditIcon
                                            size={16}
                                            viewBox="0 -960 960 960"
                                            cursor={'pointer'}
                                            onClick={() => openEditNameModal(data?.id)}
                                        />
                                        <DeleteBucketIcon
                                            size={20}
                                            viewBox="0 0 20 20"
                                            onClick={() => setProjectToDelete(data?.id)}
                                            cursor={'pointer'}
                                        />

                                        <Button
                                            variant="green"
                                            size="sm"
                                            onClick={() => {
                                                navigate(
                                                    `${routes.PROJECT_DETAILS.path}`.replace(':id', data?.id.toString())
                                                )
                                            }}
                                        >
                                            <EyeIcon />
                                        </Button>
                                    </Flexbox>
                                </>
                            )
                        }
                    ]}
                    columns={[
                        {
                            label: t('upload_pdf:table:title'),
                            width: '28%',
                            cellRender: data => (
                                <TableDataCell isBold>
                                    <p>{data?.title}</p>
                                </TableDataCell>
                            )
                        },

                        {
                            label: t('upload_pdf:table:date'),
                            sortName: 'date',
                            width: '20%',
                            cellRender: data => (
                                <Flexbox gap={2}>
                                    <Flexbox align="center" gap={1}>
                                        <CalendarIcon size={12} viewBox="0 0 12 12" />
                                        {formatDate(data?.createdAt, 'DD/MM/YYYY')}
                                    </Flexbox>
                                    <Flexbox align="center" gap={1}>
                                        <ClockIcon size={12} />
                                        {formatDate(data?.createdAt, 'HH:mm')}
                                    </Flexbox>
                                </Flexbox>
                            )
                        },

                        {
                            label: t('upload_pdf:table:status'),
                            width: '15%',
                            cellRender: data => <ChipStatus status={data?.status} />
                        },
                        {
                            label: ' ',
                            width: '35%',
                            alignment: 'right',
                            cellRender: data => (
                                <Flexbox align="center" gap={4}>
                                    <Button
                                        variant="green"
                                        size="sm"
                                        onClick={() => {
                                            navigate(
                                                `${routes.PROJECT_DETAILS.path}`.replace(':id', data?.id.toString())
                                            )
                                        }}
                                    >
                                        <EyeIcon />
                                        <p>{t('commons:details')}</p>
                                    </Button>
                                    <EditIcon
                                        size={20}
                                        viewBox="0 -960 960 960"
                                        cursor={'pointer'}
                                        onClick={() => openEditNameModal(data?.id)}
                                    />
                                    <DeleteBucketIcon
                                        size={20}
                                        viewBox="0 0 20 20"
                                        onClick={() => setProjectToDelete(data?.id)}
                                        cursor={'pointer'}
                                    />
                                </Flexbox>
                            )
                        }
                    ]}
                />
            </StyledProjectsTableContainer>

            {editNameModal.isOpen && (
                <EditFileNameModal
                    projectData={projectData.find(project => project.id === editNameModal.projectId) || null}
                    onConfirm={renameFileTitle}
                    isEdit
                    onClose={() =>
                        setEditNameModal({
                            isOpen: false,
                            projectId: null
                        })
                    }
                    type={FileType.project}
                />
            )}
        </>
    )
}
