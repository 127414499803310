import {StyledTableWrapper} from '@/features/signup-requests/components/requests-status/style.ts'
import {Table} from '@components/commons/table/Table.tsx'
import {columnsApprovedTable} from '@/features/signup-requests/components/requests-status/approved/columnsApprovedTable.tsx'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {SignUpRequestsProps} from '@/features/signup-requests/types.ts'

export const ApprovedRequests: FC<SignUpRequestsProps> = ({data, isLoading, sorter, isMobile}) => {
    const {t} = useTranslation()

    return (
        <>
            <StyledTableWrapper>
                <Table
                    sorter={sorter}
                    columns={columnsApprovedTable(isMobile, t)}
                    data={data}
                    isLoading={isLoading}
                    isMobile={isMobile}
                />
            </StyledTableWrapper>
        </>
    )
}
