import styled, {css, DefaultTheme} from 'styled-components'
import {spacing} from '@/theme/theme.ts'

export const StyledOverlay = styled.div(
    ({theme: {zIndex}}: {theme: DefaultTheme; overlayVariant?: 'filled' | 'gradient'}) => css`
        position: fixed;
        inset: 0;
        background: rgba(52, 64, 84, 0.7);
        opacity: 1;
        z-index: ${zIndex.modalOverlay};
        backdrop-filter: blur(5px);
    `
)

interface StyledModalProps {
    width?: number
    theme: DefaultTheme
}

export const StyledModal = styled.div(
    ({width, theme: {palette, zIndex, shadows}}: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow-y: auto;
        box-shadow: ${shadows.lg};
        display: grid;
        grid-template-columns: 1fr auto;
    `
)
