import {useMutation, useQueryClient} from '@tanstack/react-query'

import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {MUTATION_KEYS, QUERY_KEYS} from '@/features/projects/keys'
import {PROJECTS_API} from '../services/projects.http'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'

export const useChangeProjectTitle = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CHANGE_FILE_TITLE],
        mutationFn: PROJECTS_API.changeProjectTitle,
        onSuccess: () => {
            toast.success(t('commons:operation_completed'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.JOB]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
