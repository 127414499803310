import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    fullWidth?: boolean
    iconOnly?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    variant?: 'primary' | 'primaryDanger' | 'secondary' | 'tertiary' | 'ghost' | 'green' | 'link'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({children, fullWidth, iconOnly, size = 'md', type = 'button', variant = 'primary', ...rest}, ref) => (
        <StyledButton
            fullWidth={fullWidth}
            iconOnly={iconOnly}
            ref={ref}
            size={size}
            type={type}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

Button.displayName = 'Button'
