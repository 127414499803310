import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {StyledChipStatusWrapper} from './styles'
import {ProjectStatus} from '../../types'
import {JOB_RESULT, JOB_STATUS, PROJECT_STATUS} from '@utilities/constants'

type ChipStatusProps = {
    status: ProjectStatus | 'completed' | 'fail'
    isMobile?: boolean
}

export const ChipStatus: FC<ChipStatusProps> = ({status, isMobile}) => {
    const {t} = useTranslation()

    const colorStatus = () => {
        switch (status) {
            case PROJECT_STATUS.success:
            case JOB_STATUS.completed:
                return JOB_RESULT.success
            case PROJECT_STATUS.fail:
                return JOB_RESULT.danger
            default:
                return JOB_RESULT.warning
        }
    }

    return (
        <StyledChipStatusWrapper status={colorStatus() as 'success' | 'danger' | 'warning'} isMobile={isMobile}>
            <p className="status-text">{t(`pdf_details:${status}`)}</p>
        </StyledChipStatusWrapper>
    )
}
