import {createBrowserRouter} from 'react-router-dom'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants'
import {UserRoute} from '@route-guards/user-route/UserRoute.tsx'
import {AdminRoute} from '@route-guards/admin-route/AdminRoute.tsx'
import {NotFound} from '@pages/not-found/NotFound.tsx'

const router = createBrowserRouter(
    [
        {
            element: <PrivateLayout />,
            children: [
                {
                    element: <UserRoute />,
                    children: [{...routes.HOME}, {...routes.PROJECT_DETAILS}]
                }
            ]
        },

        {
            element: <PrivateLayout />,
            children: [
                {
                    element: <AdminRoute />,
                    children: [{...routes.SIGNUP_REQUESTS}]
                }
            ]
        },

        {
            element: <PublicRoute />, // anonymous user layout route
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.LOGIN}, {...routes.SIGNUP}, {...routes.CHANGE_PASSWORD}]
                }
            ]
        },
        {
            path: '*', // Not found route
            element: <NotFound />
        }
    ],
    {
        basename: '/'
    }
)

export default router
