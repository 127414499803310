import {TableColumn, TableSorter} from '@components/commons/table/Table.tsx'
import {StyledTHead, StyledTH, StyledTHLabel} from '@/components/commons/table/desktop-t-head/style'
import {ChevronDownIcon, ChevronSelectorVerticalIcon, ChevronUpIcon} from 'src/components/ui/icon'

interface DesktopTHeadProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    sorter?: TableSorter<TSortName>
}

export const DesktopTHead = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    sorter
}: DesktopTHeadProps<TData, TSortName>) => {
    return (
        <StyledTHead>
            {columns.map((column, index) => {
                const sorterDirection = sorter?.orderBy === column.sortName ? sorter?.orderDirection : undefined

                const handleSort = () => {
                    const nextSortDirection =
                        sorterDirection == 'desc' ? 'asc' : sorterDirection == 'asc' ? undefined : 'desc'
                    sorter?.onSort({orderDirection: nextSortDirection, orderBy: column.sortName})
                }

                return (
                    <StyledTH width={column.width} alignment={column.alignment} key={index}>
                        <StyledTHLabel>
                            <h3>{column.label}</h3>
                        </StyledTHLabel>
                        {!!column.sortName &&
                            !!sorter?.onSort &&
                            (sorterDirection == 'asc' ? (
                                <ChevronUpIcon size={16} onClick={handleSort} />
                            ) : sorterDirection == 'desc' ? (
                                <ChevronDownIcon size={16} onClick={handleSort} />
                            ) : (
                                <ChevronSelectorVerticalIcon size={16} onClick={handleSort} />
                            ))}
                    </StyledTH>
                )
            })}
        </StyledTHead>
    )
}

DesktopTHead.displayName = 'DesktopTHead'
