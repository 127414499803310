import {FC, ReactNode} from 'react'
import {StyledInputWrapper} from '@components/ui/input-wrapper/style.ts'

interface InputWrapperProps {
    children: ReactNode
    className?: string
    hasError: boolean
    touched: boolean
}

export const InputWrapper: FC<InputWrapperProps> = ({children, className, hasError, touched}) => {
    return (
        <StyledInputWrapper className={className} touched={touched} hasError={hasError} align="center" gap={2}>
            {children}
        </StyledInputWrapper>
    )
}

InputWrapper.displayName = 'InputWrapper'
