import {ChevronIcon} from '@/components/ui/icon'
import * as Accordion from '@radix-ui/react-accordion'
import {Dispatch, FC, SetStateAction} from 'react'
import {StyledDescriptionParagraph, StyledTriggerContainer} from './style'
import {useTranslation} from 'react-i18next'

type AccordionProps = {
    description: string
    setOpen: Dispatch<SetStateAction<boolean>>
    open: boolean
}

export const AccordionDetails: FC<AccordionProps> = ({description, open, setOpen}) => {
    const {t} = useTranslation()

    return (
        <Accordion.Root type="multiple">
            <Accordion.AccordionItem value="panel1">
                <Accordion.AccordionTrigger>
                    <StyledTriggerContainer onClick={() => setOpen(!open)} open={open}>
                        <p>{t('sidebar_details:accordion_trigger')}</p>
                        <ChevronIcon size={16} viewBox="0 0 16 16" />
                    </StyledTriggerContainer>
                </Accordion.AccordionTrigger>
                <Accordion.AccordionContent>
                    <StyledDescriptionParagraph>{description}</StyledDescriptionParagraph>
                </Accordion.AccordionContent>
            </Accordion.AccordionItem>
        </Accordion.Root>
    )
}
