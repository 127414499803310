import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledTitle = styled.h4`
    ${({theme}) => css`
        color: ${theme.palette.neutral['700']};
        ${theme.typography.displaySm};
        font-weight: 700;

        ${theme.mediaQueries.m} {
            ${theme.typography.displayMd};
        }
    `}
`
export const StyledContainerElaborationHeader = styled(Flexbox)`
    ${({theme}) => css`
        margin-bottom: ${theme.spacing * 5}px;

        & > :last-child {
            margin-left: auto;
        }

        & .input-search {
            border-radius: ${theme.spacing * 3}px !important;
        }
    `}
`

export const StyledProjectsTableContainer = styled.div`
    ${({theme: {mediaQueries}}) => css`
        height: calc(100vh - 390px);
        ${mediaQueries.m} {
            width: 100%;
            height: 40vh;
        }

        ${mediaQueries.landscapeMobile} {
            height: 100vh;
        }
    `}
`
