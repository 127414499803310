import {useMutation, useQueryClient} from '@tanstack/react-query'
import {SIGN_UP_REQUESTS_API} from '@/features/signup-requests/services/signupRequests.http.ts'
import {QUERY_KEYS} from '@/features/signup-requests/keys.ts'
import {actionMultipleRequestsPayload} from '@/features/signup-requests/types.ts'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'

export const useActionMultipleRequests = () => {
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationFn: (request: actionMultipleRequestsPayload) => SIGN_UP_REQUESTS_API.actionMultipleRequests(request),
        onSuccess: () => {
            toast.success(t('toast:success'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SIGNUP_REQUESTS]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
