import {Modal} from '@components/ui/modal/Modal.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckGreenCircleIcon} from '@components/ui/icon'
import {StyledHeaderWrapper} from '@/features/auth/components/password-recovery-modal/style.ts'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'

export const ModalPasswordUpdated = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    return (
        <Modal closeIconOffset="sm" width={468}>
            <Flexbox gap={6} padding={6}>
                <CheckGreenCircleIcon size={48} viewBox="0 0 48 48" />
                <div>
                    <StyledHeaderWrapper direction={'column'} gap={2} style={{paddingBottom: '16px'}}>
                        <h4>{t('change_password:success:title')}</h4>
                        <p>{t('change_password:success:subtitle')}</p>
                    </StyledHeaderWrapper>
                    <Button variant={'green'} size={'lg'} onClick={() => navigate(routes.LOGIN.path)}>
                        {t('login:cta')}
                    </Button>
                </div>
            </Flexbox>
        </Modal>
    )
}
