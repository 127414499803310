import {z} from 'zod'

export const ProjectStatusSchema = z.enum(['fail', 'pending', 'completed'])

export const ProjectSchema = z.object({
    id: z.number(),
    title: z.string(),
    createdAt: z.string().datetime(), // 2024-02-19T10:25:52.732+00:00
    status: ProjectStatusSchema
})

// job schema

export const JobResultSchema = z.object({
    id: z.number(),
    jobId: z.number(),
    isCorrect: z.boolean(),
    response: z.string(),
    createdAt: z.string(), // 2024-02-19T10:25:52.732+00:00
    question: z.object({
        id: z.number(),
        question: z.string(),
        createdAt: z.string() // 2024-02-19T10:25:52.732+00:00
    })
})

export const JobSchema = z.object({
    id: z.number(),
    name: z.string(),
    score: z.number().nullish(),
    status: ProjectStatusSchema,
    createdAt: z.string(), // 2024-02-19T10:25:52.732+00:00
    responses: z.array(JobResultSchema),
    fileName: z.string(),
    avgCompletedInMin: z.number()
})

export const ProjectDetailSchema = z.object({
    id: z.number(),
    title: z.string(),
    createdAt: z.string(), // 2024-02-19T10:25:52.732+00:00
    status: ProjectStatusSchema,
    jobs: z.array(JobSchema)
})

export const FileTypeSchema = z.enum(['file', 'project'])
