export const SIGNUP_UP_STATUS = {
    pending: 'pending',
    approved: 'approved',
    rejected: 'rejected'
}

export const USER_ROLES = {
    admin: 'admin',
    user: 'user'
}

export const SORT_BY = {
    asc: 'asc',
    desc: 'desc'
}

export const PROJECT_STATUS = {
    success: 'success',
    fail: 'fail',
    pending: 'pending'
}

export const JOB_STATUS = {
    completed: 'completed',
    fail: 'fail'
}

export const JOB_RESULT = {
    success: 'success',
    danger: 'danger',
    warning: 'warning'
}
