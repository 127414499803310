import {useTranslation} from 'react-i18next'
import {StyledScoreBannerWrapper} from './style.ts'
import {FC} from 'react'
import {Job} from '@/features/projects/types.ts'

type TotalScoreBannerProps = {
    job: Job | null
}

export const TotalScoreBanner: FC<TotalScoreBannerProps> = ({job}) => {
    const {t} = useTranslation()

    const score = () => {
        const scoreNumber = job?.score || 0
        switch (true) {
            case scoreNumber <= 60:
                return 'danger'
            case scoreNumber <= 80:
                return 'success'
            default:
                return 'success'
        }
    }

    return (
        <StyledScoreBannerWrapper score={score()}>
            <h4>{t('sidebar_details:score_title')}</h4>
            <p>{job?.score || 0}%</p>
        </StyledScoreBannerWrapper>
    )
}
