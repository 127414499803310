import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/features/projects/keys'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {PROJECTS_API} from '@/features/projects/services/projects.http.ts'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'

type useCreateProjectProps = {
    onSuccess: () => void
}

export const useCreateProject = ({onSuccess}: useCreateProjectProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_PROJECT],
        mutationFn: PROJECTS_API.createProject,
        onSuccess: () => {
            onSuccess?.()
            toast.success(t('toast:successUpload'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ME]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
