import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/signup-requests/keys.ts'
import {SIGN_UP_REQUESTS_API} from '@/features/signup-requests/services/signupRequests.http.ts'

export const useGetAdminConfig = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_CONFIG],
        queryFn: SIGN_UP_REQUESTS_API.adminConfig
    })
}
