import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckGreenCircleIcon} from '@components/ui/icon'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {FC, useState} from 'react'
import {StyledHeaderWrapper} from './style.ts'
import {useTranslation} from 'react-i18next'
import {PasswordRecoveryForm} from '@/features/auth/components/password-recovery-form/PasswordRecoveryForm.tsx'
import {Spinner} from '@/components/ui/spinner/Spinner.tsx'

type PasswordRecoveryModalProps = {
    onClose: () => void
}

export const PasswordRecoveryModal: FC<PasswordRecoveryModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const [isSuccessfullySent, setIsSuccessfullySent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <Modal width={400} onClose={onClose} closeIconOffset="sm">
                    {isSuccessfullySent ? (
                        <Flexbox gap={6} padding={6}>
                            <CheckGreenCircleIcon size={48} viewBox="0 0 48 48" />
                            <StyledHeaderWrapper direction={'column'}>
                                <h4>{t('recovery_password:success_recovery:title')}</h4>
                                <p>{t('recovery_password:success_recovery:subtitle')}</p>
                            </StyledHeaderWrapper>
                        </Flexbox>
                    ) : (
                        <PasswordRecoveryForm
                            primaryAction={() => setIsSuccessfullySent(true)}
                            secondaryAction={onClose}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                        />
                    )}
                </Modal>
            )}
        </>
    )
}

PasswordRecoveryModal.displayName = 'PasswordRecoveryModal'
