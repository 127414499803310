import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {MinusIcon, PlusIcon} from '@components/ui/icon'
import {FC, useMemo, useState} from 'react'
import {
    StyledButtonCounter,
    StyledContainerCounter
} from '@/features/signup-requests/components/requests-status/style.ts'
import {useUpdateCredits} from '@/features/signup-requests/queries/useUpdateCredits.ts'
import {RequestType} from '@/features/signup-requests/types.ts'

type CounterCreditsProps = {
    data: RequestType
}

export const CounterCredits: FC<CounterCreditsProps> = ({data}) => {
    const [count, setCount] = useState(data?.maxFileUpload ?? 0)
    const {mutate: updateCredits} = useUpdateCredits()
    const MAX_CREDITS = 50
    const MIN_CREDITS = useMemo(() => {
        return data?.user?.fileUploaded <= 10 ? 10 : data?.user?.fileUploaded
    }, [data?.user?.fileUploaded]) //file uploaded by user

    const disableAdd = count === MAX_CREDITS
    const disableRemove = useMemo(() => {
        return count <= MIN_CREDITS || !data?.user
    }, [count, MIN_CREDITS, data?.user])

    const handleCount = (type: string) => {
        if (disableAdd && type === 'add') return
        if (disableRemove && type === 'remove') return

        const updatedCount = type === 'add' ? Math.min(count + 1, MAX_CREDITS) : Math.max(count - 1, MIN_CREDITS)
        setCount(updatedCount)
        handleUpdateCredits(updatedCount)
    }

    const handleUpdateCredits = (updatedCount: number) => {
        if (updatedCount < MIN_CREDITS || updatedCount > MAX_CREDITS) {
            return
        } else {
            updateCredits({id: data?.id, credits: updatedCount})
        }
    }

    return (
        <StyledContainerCounter>
            <StyledButtonCounter disabled={disableRemove} onClick={() => handleCount('remove')}>
                <MinusIcon viewBox="0 -960 960 960" />
            </StyledButtonCounter>
            <Flexbox justify="center" align="center">
                <p>{count}</p>
            </Flexbox>
            <StyledButtonCounter disabled={disableAdd} onClick={() => handleCount('add')}>
                <PlusIcon viewBox="0 -960 960 960" />
            </StyledButtonCounter>
        </StyledContainerCounter>
    )
}
