import styled, {css} from 'styled-components'

export const StyledHeaderWrapper = styled.div`
    ${({theme: {typography, spacing, palette}}) => css`
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: ${palette.neutral[800]};

        & h4 {
            ${typography.textMd};
            font-weight: 700;
            margin-bottom: ${spacing * 2}px;
        }
    `}
`
export const StyledStatusWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        margin-bottom: ${spacing * 4}px;

        & p {
            color: ${palette.neutral[700]};
        }
    `}
`
