import {createPortal} from 'react-dom'
import {FC, ReactNode, useMemo} from 'react'
import {
    StyledSidebarDetails,
    StyledOverlaySidebar,
    StyledSidebarTextContainer
} from '@components/commons/sidebar-details/style.ts'
import {DefaultNamespace} from 'i18next'
import {FolderIcon, XCloseIcon} from '@/components/ui/icon'
import {Job} from '@/features/projects/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {truncateText} from '@utilities/helpers.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'

type SidebarDetailsProps = {
    onClose: () => void
    open: boolean
    children: ReactNode
    title: string
    subtitle: DefaultNamespace
    sidebarInfo: Job | null
}
export const SidebarDetails: FC<SidebarDetailsProps> = ({open, onClose, children, title, subtitle, sidebarInfo}) => {
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])

    return createPortal(
        <>
            <StyledOverlaySidebar onClick={onClose} open={open} />
            <StyledSidebarDetails open={open}>
                <StyledSidebarTextContainer>
                    <div className={'title'}>
                        <Flexbox align={'center'} gap={2}>
                            <FolderIcon size={20} viewBox="0 -960 960 960" />
                            <span>{truncateText(title, isMobile ? 8 : 20)}/</span>
                            <h4>{sidebarInfo ? truncateText(sidebarInfo?.name, isMobile ? 10 : 20) : ''}</h4>
                        </Flexbox>
                        <p>{subtitle}</p>
                    </div>
                    <XCloseIcon onClick={onClose} cursor={'pointer'} />
                </StyledSidebarTextContainer>
                {children}
            </StyledSidebarDetails>
        </>,
        document.body
    )
}
