import styled, {css} from 'styled-components'

export const StyledColumn = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.s} {
            display: flex;
            flex-direction: row-reverse;
            gap: ${spacing * 6}px;
        }

        .card-container {
            flex: 1;
        }
    `}
`
