import {css} from 'styled-components'
const defaultFontFamily = `'DM sans', sans-serif`

export const typography = {
    displayXl: css`
        font-family: ${defaultFontFamily};
        font-size: 3.5rem; /* 56px */
        line-height: 100px;
        letter-spacing: -0.02em;
    `,
    displayLg: css`
        font-family: ${defaultFontFamily};
        font-size: 2.25rem; /* 36px */
        line-height: 44px;
        letter-spacing: -0.02em;
    `,
    displayMd: css`
        font-family: ${defaultFontFamily};
        font-size: 1.5rem; /* 24px */
        line-height: 32px;
    `,
    displaySm: css`
        font-family: ${defaultFontFamily};
        font-size: 1.25rem; /* 20px */
        line-height: 32px;
    `,
    textXl: css`
        font-family: ${defaultFontFamily};
        font-size: 1.125rem; /* 18px */
        line-height: 28px;
    `,
    textLg: css`
        font-family: ${defaultFontFamily};
        font-size: 1rem; /* 16px */
        line-height: 20px;
    `,
    textMd: css`
        font-family: ${defaultFontFamily};
        font-size: 0.875rem; /* 14px */
        line-height: 20px;
    `,
    textSm: css`
        font-family: ${defaultFontFamily};
        font-size: 0.75rem; /* 12px */
        line-height: 18px;
    `
}
