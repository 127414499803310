import {
    StyledSwitchInput,
    StyledSwitchLabel,
    StyledSwitchThumb,
    StyledSwitchWrapper
} from '@components/commons/switch/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, ReactNode} from 'react'

type SwitchProps = {
    icon?: ReactNode
    label?: string
    name?: string
    onChange?: (e: boolean) => void
    defaultChecked?: boolean
    value?: boolean
}

export const Switch: FC<SwitchProps> = ({value, icon, label, name, onChange, defaultChecked}) => {
    return (
        <StyledSwitchWrapper>
            <Flexbox justify={'flex-start'} align={'center'} gap={2}>
                <StyledSwitchLabel htmlFor={name}>{label}</StyledSwitchLabel>
                {icon}
            </Flexbox>

            <StyledSwitchInput id={name} onCheckedChange={onChange} defaultChecked={defaultChecked} checked={value}>
                <StyledSwitchThumb />
            </StyledSwitchInput>
        </StyledSwitchWrapper>
    )
}
