import styled, {css} from 'styled-components'
import {User} from '@/features/auth/type.ts'

export const HeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 80px;
    `}
`

export const HeaderGrid = styled.div<{user?: User | null}>`
    ${({user, theme}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        height: 100%;
        padding: ${theme.spacing * 5}px 0;

        img {
            justify-self: center;
        }

        ${theme.mediaQueries.m} {
            img {
                justify-self: self-start;
            }
        }

        ${user &&
        css`
            .user-email {
                display: none;

                ${theme.mediaQueries.m} {
                    display: flex;
                }
            }

            grid-template-columns: 1fr auto 1fr;
            grid-template-areas: ' . logo logout';

            .logout-container {
                grid-area: logout;
                justify-self: end;
                color: ${theme.palette.neutral['600']};
                ${theme.typography.textMd};
            }

            img {
                grid-area: logo;
                justify-self: center;
                cursor: pointer;
            }
        }
        `}
    `}
`

export const MenuItems = styled.div`
    ${({theme}) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 3}px;
    `}
`
