import styled, {css, DefaultTheme} from 'styled-components'
import {spacing} from '@/theme/theme.ts'

export const DESKTOP_TABLE_COLUMNS_PADDING = `${spacing * 4}px`
export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

export const StyledDesktopTable = styled.div(
    ({theme: {palette}}: {theme: DefaultTheme}) => css`
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;
        background: ${palette.neutral.white};
        overflow: hidden;
    `
)
