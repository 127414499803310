import {useMutation, useQueryClient} from '@tanstack/react-query'
import {SIGN_UP_REQUESTS_API} from '@/features/signup-requests/services/signupRequests.http.ts'
import {MUTATION_KEYS, QUERY_KEYS} from '@/features/signup-requests/keys.ts'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import {useTranslation} from 'react-i18next'

export const useUpdateCredits = () => {
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_CREDITS],
        mutationFn: (payload: {id: number; credits: number}) => SIGN_UP_REQUESTS_API.updateCredits(payload),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SIGNUP_REQUESTS]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
