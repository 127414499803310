import styled, {css, DefaultTheme} from 'styled-components'

export const StyledTabSectionWrapper = styled.div`
    ${({theme}) => css`
        display: flex;
        gap: ${theme.spacing * 8}px;
        padding: ${theme.spacing * 4}px 0;
        justify-content: center;

        ${theme.mediaQueries.m} {
            justify-content: flex-start;
        }
    `}
`
export const StyledTabItem = styled.div`
    ${({theme, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        ${theme.typography.textMd};
        white-space: nowrap;
        font-weight: 700;
        color: ${theme.palette.neutral['700']};
        cursor: pointer;

        ${isActive &&
        css`
            color: ${theme.palette.green['400']};
            text-decoration: underline;
            text-underline-offset: 16px;
            text-decoration-thickness: 2px;
        `}

        ${theme.mediaQueries.l} {
            ${theme.typography.textLg};
        }
    `}
`
