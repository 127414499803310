import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledPageNotFoundContainer} from '@pages/not-found/style.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuthStore} from '@/store/useAuthStore.tsx'
import {routes, USER_ROLES} from '@utilities/constants'

export const NotFound = () => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])
    const navigate = useNavigate()
    const {user, accessToken} = useAuthStore()

    const handleRedirect = () => {
        const isAdmin = user?.type === USER_ROLES.admin
        if (accessToken) {
            const path = isAdmin ? routes.SIGNUP_REQUESTS.path : routes.HOME.path
            navigate(path)
        } else {
            navigate(routes.LOGIN.path)
        }
    }
    return (
        <StyledPageNotFoundContainer
            width={'100%'}
            height={'100%'}
            align={'flex-start'}
            justify={'center'}
            direction={'column'}
            padding={10}
            gap={isMobile ? 3 : 0}
        >
            <p className={'paragraph'}>{t('not_found:paragraph')}</p>

            <h3>{t('not_found:title')}</h3>

            <p className={'subtitle'}>{t('not_found:subtitle')}</p>
            <Button variant={'green'} onClick={handleRedirect}>
                {t('not_found:cta')}
            </Button>
        </StyledPageNotFoundContainer>
    )
}
