import styled, {css, DefaultTheme} from 'styled-components'

export const StyledTHead = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        width: 50%;
        justify-content: flex-start;
        gap: ${spacing * 2}px;

        & > svg {
            cursor: pointer;
            flex: none;
        }
    `
)

export const StyledSorterContainer = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        user-select: none;
        cursor: pointer;
        height: 26px;
        ${typography.textSm};
        color: ${palette.neutral[500]};
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: 10px 14px 10px 14px;
        border: 1px solid ${palette.neutral[300]};
        border-radius: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
    `}
`
