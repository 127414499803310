import axios from '@/services/axiosInstance'
import {createFormData} from '@/utilities/helpers'
import {changeJobTitlePayload, changeProjectTitlePayload, ProjectPayload} from '@/features/projects/types.ts'

export const PROJECTS_API = {
    getProjects: (orderBy: string) => axios.get('/projects', {params: {orderBy}}),
    createProject: (payload: ProjectPayload) =>
        axios
            .post('/projects', createFormData(payload.files), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: payload.onProgress
            })
            .then(response => response.data),
    changeProjectTitle: (payload: changeProjectTitlePayload) =>
        axios.patch(`/projects/${payload?.id}`, {title: payload?.title}),
    deleteProject: (id: number) => axios.delete(`/projects/${id}`),
    getProjectDetail: (id: number) => axios.get(`/projects/${id}/jobs`).then(response => response.data),
    changeJobTitle: (payload: changeJobTitlePayload) =>
        axios.patch(`/projects/${payload?.projectID}/jobs/${payload?.jobID}`, {name: payload?.title})
}
