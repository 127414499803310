import styled, {css, DefaultTheme} from 'styled-components'

export const StyledContainerProgress = styled.div`
    ${({theme}) => css`
        height: 20px;
        width: 300px;
        position: relative;
        background-color: ${theme.palette.neutral.white};
        border-radius: 50px;
    `}
`

type StyledProgressProps = {
    theme: DefaultTheme
    value: number | undefined
    max: number
}
export const StyledProgress = styled.div`
    ${({theme, value, max}: StyledProgressProps) => css`
        position: absolute;
        height: 100%;
        width: ${value ? (value / max) * 100 : 0}%;
        background-color: ${theme.palette.violet[700]};
        border-radius: 50px;
    `}
`
