import {z} from 'zod'

export const SignUpFormSchema = z
    .object({
        email: z.string().min(1, {message: 'errors:email'}).email({message: 'errors:email_valid'}),
        password: z.string().min(8, {message: 'errors:password'}),
        passwordConfirmation: z.string().min(8, {message: 'errors:password'})
    })
    .refine(data => data.password === data.passwordConfirmation, {
        message: 'errors:password_match',
        path: ['passwordConfirmation']
    })

export type SignUpValidationSchema = z.infer<typeof SignUpFormSchema>
