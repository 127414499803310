import {InputText} from '@/components/commons/input-text/InputText'
import {CheckGreenCircleIcon, XCloseIcon} from '@/components/ui/icon'
import {Modal} from '@/components/ui/modal/Modal'
import {zodResolver} from '@hookform/resolvers/zod'
import {FC} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {UploadFileFormSchema, UploadFileValidationSchema} from './UploadFileFormSchema'
import {StyledConfirmButton, StyledContainerCloseIcon, StyledHeaderWrapper, StyledModalContainer} from './style'
import {FileType, Job, Project, ProjectDetail} from '@/features/projects/types.ts'

type EditFileNameModalProps = {
    onClose?: () => void
    projectData: ProjectDetail | Project | Job | null
    onConfirm: (projectId: number, title: string) => void
    isEdit?: boolean
    type?: string
}

export const EditFileNameModal: FC<EditFileNameModalProps> = ({
    projectData,
    onClose,
    onConfirm,
    isEdit = false,
    type = FileType.file
}) => {
    const {t} = useTranslation()

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting, isValid}
    } = useForm<UploadFileValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(UploadFileFormSchema)
    })

    const title = projectData && 'title' in projectData ? projectData.title : projectData?.name

    const onSubmit: SubmitHandler<UploadFileValidationSchema> = ({title}) => {
        const projectId = projectData?.id || 0
        onConfirm(projectId, title)
    }

    return (
        <Modal width={400} onClose={onClose} closeIconOffset="sm">
            <StyledModalContainer onSubmit={handleSubmit(onSubmit)}>
                {!isEdit && <CheckGreenCircleIcon size={48} viewBox="0 0 48 48" />}

                <StyledHeaderWrapper direction="column" gap={2}>
                    <h4>{t(isEdit ? `upload_pdf:modal:edit_title_${type}` : 'upload_pdf:modal:title')}</h4>
                    <p>{t('upload_pdf:modal:subtitle')}</p>
                </StyledHeaderWrapper>
                {onClose && (
                    <StyledContainerCloseIcon>
                        <XCloseIcon size={24} viewBox="0 0 24 24" onClick={onClose} />
                    </StyledContainerCloseIcon>
                )}

                <InputText
                    type={'text'}
                    label={t(`form:upload_label_${type}`)}
                    placeholder={t(`form:upload_label_${type}`)}
                    errorMessage={t(errors.title?.message || '')}
                    {...register('title')}
                    defaultValue={isEdit ? title : ''}
                />

                <StyledConfirmButton
                    variant={'green'}
                    type="submit"
                    className="confirmButton"
                    fullWidth
                    disabled={isSubmitting || !isValid}
                >
                    {t('commons:confirm')}
                </StyledConfirmButton>
            </StyledModalContainer>
        </Modal>
    )
}
