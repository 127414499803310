import {z} from 'zod'
import {JobSchema, ProjectDetailSchema, ProjectSchema, ProjectStatusSchema} from './schema'
import {AxiosProgressEvent} from 'axios'

//Project Types
export type ProjectStatus = z.infer<typeof ProjectStatusSchema>
export type Project = z.infer<typeof ProjectSchema>
export type ProjectDetail = z.infer<typeof ProjectDetailSchema>

//Jobs types
export type Job = z.infer<typeof JobSchema>

export type changeProjectTitlePayload = {
    id: number
    title: string
}

export type ProjectPayload = {
    files: File[]
    onProgress: (progressEvent: AxiosProgressEvent) => void
}

export type changeJobTitlePayload = {
    projectID: number
    jobID: number
    title: string
}

export const FileType = {
    file: 'file',
    project: 'project'
}
