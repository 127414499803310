export const QUERY_KEYS = {
    PROJECTS: 'projects',
    JOB: 'job',
    ME: 'me'
} as const

export const MUTATION_KEYS = {
    CREATE_PROJECT: 'create-project',
    CHANGE_FILE_TITLE: 'change-file-title',
    DELETE_PROJECT: 'delete-project',
    CHANGE_JOB_TITLE: 'change-job-title'
} as const
