import styled, {css, DefaultTheme} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledTableWrapper = styled.div`
    ${({theme: {mediaQueries, spacing, truncateText}}) => css`
        height: calc(100vh - 390px);

        ${mediaQueries.landscapeMobile} {
            height: 100vh;
            margin-bottom: ${spacing * 10}px;
        }

        ${mediaQueries.m} {
            width: 100%;
        }

        .email {
            ${truncateText};
            max-width: 50px;

            ${mediaQueries.s} {
                max-width: 150px;
            }

            ${mediaQueries.m} {
                max-width: 200px;
            }

            ${mediaQueries.l} {
                max-width: 250px;
            }

            ${mediaQueries.xl} {
                max-width: 400px;
            }
        }

        .approved-email {
            ${truncateText};
            max-width: 150px;

            ${mediaQueries.xs} {
                max-width: 200px;
            }

            ${mediaQueries.s} {
                max-width: 350px;
            }

            ${mediaQueries.m} {
                max-width: 500px;
            }

            ${mediaQueries.l} {
                max-width: 700px;
            }
        }
    `}
`

export const StyledButtonToAccept = styled(Button)`
    ${({theme}) => css`
        ${theme.typography.textMd}
        font-weight: 400;
    `}
`

export const StyledContainerCounter = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: ${theme.spacing * 2}px;
        width: 100%;

        & p {
            ${theme.typography.textMd}
            font-weight: 700;
            text-align: center;
        }
    `}
`

export const StyledButtonCounter = styled.div`
    ${({theme, disabled}: {theme: DefaultTheme; disabled?: boolean}) => css`
        display: flex;
        width: 100%;
        border: 1px solid ${theme.palette.green[400]};
        border-radius: 4px;
        cursor: pointer;

        ${disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}

        svg {
            width: 20px;
            height: 20px;
            fill: ${theme.palette.green[400]};
        }
    `}
`
