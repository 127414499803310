import {useMutation, useQueryClient} from '@tanstack/react-query'

import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'
import {handleGenericError} from '@utilities/helpers.ts'
import {AUTH_API} from '@/features/auth/services/auth.http.ts'
import {MUTATION_KEYS} from '@/features/auth/keys.ts'
import {QUERY_KEYS} from '@/features/signup-requests/keys.ts'
import {QUERY_KEYS as QUERY_KEYS_AUTH} from '@/features/projects/keys.ts'

export const useLogin = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.LOGIN],
        mutationFn: AUTH_API.login,
        onSuccess: () => {
            toast.success(t('toast:successfullyLogged'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SIGNUP_REQUESTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS_AUTH.ME]})
        },
        onError: error => {
            const errorObject = error as AxiosError
            handleGenericError({error: errorObject, t})
        }
    })
}
