import styled, {DefaultTheme, css} from 'styled-components'

type StyledTriggerContainerProps = {
    theme: DefaultTheme
    open: boolean
}

export const StyledTriggerContainer = styled.div<StyledTriggerContainerProps>`
    ${({theme: {spacing, typography, palette}, open}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: pointer;

        & > p {
            color: ${palette.neutral[500]} ${typography.textSm};
            text-decoration: underline;
        }

        svg {
            transform: ${open && 'rotate(180deg);'};
        }

        .accordion-description {
            ${typography.textSm};
        }
    `}
`
export const StyledDescriptionParagraph = styled.p`
    ${({theme: {typography, spacing, palette}}) => css`
        color: ${palette.neutral[800]};
        ${typography.textSm};
        margin-top: ${spacing * 4}px;
    `}
`
